export default {
  'sidebar': {
    'dashboard': 'Dashboard',
    'manage_users': 'Benutzer Verwalten',
    'users': 'Benutzer',
    'accounts': 'Kunden',
    'devices': 'Geräte',
    'policy': 'Konfiguration',
    'reports': 'Berichte',
    'administration': 'Verwaltung',
    'resellers': 'Händler',
    'users_reseller': 'Benutzer: Händler'
  },
  'general': {
    'api_key': 'API KEY',
    'submit': 'Absenden',
    'add': 'Hinzufügen',
    'search': 'Suche',
    'profile': 'Profil',
    'logout': 'Ausloggen',
    'user_profile': 'Benutzerprofil',
    'password': 'Passwort',
    'change_password': 'Passwort ändern',
    'new_password': 'Neues Passwort',
    'repeat_password': 'Passwort wiederholen',
    'mach_password': "Passwort stimmt nicht überein",
    'min_length_password': "Das Passwort muss mehr als 8 Zeichen lang sein",
    'uppercase_password': "Das Passwort muss einen Großbuchstaben enthalten",
    'lowercase_password': "Das Passwort muss Kleinbuchstaben enthalten",
    'need_number_password': "Das Passwort muss eine Zahl enthalten",
    'special_ch_password': "Das Passwort muss ein Sonderzeichen enthalten #?!@$%^&*-",
    'required': 'erforderlich',
    'language': 'Sprache',
    'of': 'von',
    'select': 'Auswählen',
    'action': 'Aktion',
    'no_records_found': 'Keine Einträge gefunden',
    'no_search_results_found': 'Keine Suchergebnisse gefunden',
    'all_devices': 'Alle Geräte',
    'all_accounts': 'Alle Konten',
    'no_edit_device': 'Sie können dieses Gerät nicht bearbeiten, da es sich in der Gruppe \'@1\' befindet. Bitte bearbeiten Sie stattdessen die Gruppe.',
    'required': 'Erforderlich',
    'read_only': 'Schreibgeschützt',
    'subscription': 'Abonnement',
    'cancel': 'Abbrechen',
    'back': 'Zurück',
    'update': 'Aktualisieren',
    'dyndns_configuration': 'DynDNS-Konfiguration',
    'username': 'Nutzername',
    'server': 'Server',
    'first_name': 'Vorname',
    'last_name': 'Nachname',
    'email': 'Email',
    'customer_data': 'Kundendaten',
    'user_data': 'Benutzerdaten',
    'demo_data': 'Données de démonstration',
    'new_demo_account': 'Nouveau compte démo',
    'name': 'Nom',
  },
  'locked': {
    'access_denied': 'Zugriff verweigert',
    'sorry': 'Entschuldigung,',
    'param': '{param}',
    'blocked_by_network_admin': 'wurde von Ihrem Netzwerkadministrator blockiert',
    'locked_page': 'Gesperrte Seite',
    'protected_by': 'Geschützt durch {param}',
    'unlock_request': 'Anfrage entsperren',
    'unlock_website_request': 'Website-Anfrage entsperren'
  },
  'errors': {
    'invalid_param': 'Bitte geben Sie einen gültigen {param} ein',
    'min_length': 'Mindestlänge von {n} Zeichen',
    'max_length': 'Maximale Länge von {n} Zeichen',
    'must_match': 'Muss übereinstimmen',
    'too_many_requests': 'Zu viele Anfragen',
  },
  'language': {
    'german': 'Deutsch',
    'english': 'English',
    'spanish': 'Español',
  },
  'dashboard': {
    'no_productivity_by_categories': 'Unproduktive Tätigkeit nach Kategorien',
    'productivity_by_categories': 'Produktive Tätigkeit nach Kategorien',
    'productivity': 'Produktive Tätigkeit',
    'noProductivity': 'Unproduktive Tätigkeit',
    'productivity_time': 'Produktive Tätigkeit nach Zeit',
    'current_productivity': 'Aktuell',
    'max_productivity': 'Maximale produktive Tätigkeit',
    'average_productivity': 'Durchschnittliche produktive Tätigkeit',
    'filter': 'Filter',
    'filter_by_time': 'Filter',
    'filter_by_device': 'Gerät',
    'today': 'Heute',
    'yesterday': 'Gestern',
    'past_7_days': 'Letzte 7 Tage',
    'past_30_days': 'Letzte 30 Tage',
    'more': 'Mehr',
    'allowed_traffic': 'Zulässiger Datenverkehr',
    'blocked_traffic': 'Blockierter Datenverkehr',
    'allowed_traffic_time': 'Zulässiger Datenverkehr nach Zeit',
    'blocked_traffic_time': 'Blockierter Datenverkehr nach Zeit',
    'allowed_traffic_regions': 'Zulässiger Datenverkehr nach Regionen',
    'blocked_traffic_regions': 'Blockierter Datenverkehr nach Regionen',
    'time': 'Zeit',
    'location': 'Ort',
    'traffic_over_time': 'Datenverkehr im Laufe der Zeit',
    'traffic_counts': 'Zählung des Datenverkehrs',
    'categories': 'Kategorien',
    'geolocations': 'Geo-Standorte',
    'count': 'Anzahl',
    'allowed_categories': 'Zulässige Kategorien',
    'blocked_categories': 'Blockierte Kategorien',
    'allowed_geolocations': 'Zulässige Geo-Standorte',
    'blocked_geolocations': 'Blockierte Geo-Standorte',
    'allowed': 'Zugelassen',
    'blocked': 'Blockiert',
    'loading_chart': 'Lade Diagramm',
    'malwares_blocked_by_time': 'Blockierte Malware nach Zeit',
    'malware_blocked_by_categories': 'Blockierte Malware nach Kategorien',
    'malware_blocked_by_regions': 'Blockierte Malware nach Regionen',
    'select_device_message': 'Bitte wählen Sie ein Gerät oder eine Gruppe aus',
    'producivity_traffic': 'Produktiver Verkehr',
    'no_producivity_traffic': 'Unproduktiver Verkehr'
  },
  'reports': {
    'day': 'Tag',
    'category_name': 'Bezeichnung der Kategorie',
    'region_name': 'Bezeichnung der Region',
    'domain_name': 'Domänenname',
    'donwload_data': 'Daten herunterladen',
    'stats_by_time_allowed': 'Erlaubte Zugriffe nach Datum',
    'stats_by_time_denied': 'Blockierte Zugriffe nach Datum',
    'stats_by_category_allowed': 'Erlaubte Zugriffe nach Kategorie',
    'stats_by_category_denied': 'Blockierte Zugriffe nach Kategorie',
    'stats_by_region_allowed': 'Erlaubte Zugriffe nach Region',
    'stats_by_region_denied': 'Blockierte Zugriffe nach Region',
    'stats_by_domain_allowed': 'Erlaubte Zugriffe nach Domäne',
    'stats_by_domain_denied': 'Blockierte Zugriffe nach Domäne',
    'stats_by_malware_host': 'Mit Malware infizierte Hosts',
    'stats_by_malware_threat': 'Blockierte Bedrohungen',
    'stats_by_productivity_categories': 'Produktive Tätigkeit nach Kategorie',
    'stats_by_no_productivity_categories': 'Unproduktive Tätigkeit nach Kategorie',
    'by_date': 'Nach Datum',
    'by_time': 'Nach Zeit',
    'categories': 'Kategorien',
    'regions': 'Regionen',
    'domains': 'Domänen',
    'malware': 'Malware',
    'productivity': 'Produktive Tätigkeit',
    'malware_host': 'Infizierter Host blockiert',
    'malware_threat': 'Bedrohungen blockiert',
    'productivity_categories': 'Kategorien Produktiver Tätigkeit',
    'no_productivity_categories': 'Kategorien unproduktiver Tätigkeit',
    'from': 'Von',
    'to': 'Bis',
    'view': 'Ansicht',
    'download': 'Herunterladen',
    'email': 'Email',
    'no_table_data': 'Keine Daten für Tabelle',
    'traffic_logs': 'Verkehrsprotokolle',
    'requests': 'Anfragen'
  },
  'devices': {
    'devices_group': 'Gerätegruppen',
    'create_group': 'Neue Gruppe erstellen',
    'create': 'Erstellen',
    'name_group': 'Gruppenname',
    'group_devices': 'Geräte gruppieren',
    'remove_group': 'Gruppe entfernen',
    'remove': 'Entfernen',
    'no_groups_found': 'Keine Gruppen gefunden',
    'edit_group': 'Gruppe bearbeiten',
    'update_group_success': 'Gruppe erfolgreich aktualisiert',
    'remove_group_success': 'Gruppe erfolgreich entfernt',
    'search_group_placeholder': 'Suche nach Gruppennamen',
    'group_name_required': 'Bitte Gruppennamen angeben',
    'group_devices_list_required': 'Die Liste der Geräte darf nicht leer sein',
    'template': 'Vorlage',
    'name_device': 'Gerät benennen',
    'remove_device': 'Gerät entfernen',
    'qr_scan': 'QR-Scan',
    'qr_upload': 'QR-Upload',
    'add_new_device': 'Neues Gerät hinzufügen',
    'i_agree': 'Ich stimme dem zu:',
    'not_license_available': 'Lizenz nicht verfügbar',
    'terms_and_conditions': 'Geschäftsbedingungen',
    'enter_device_code': 'Gerätecode eingeben',
    'scan_qr_code': 'QR-Code scannen',
    'device_list': 'Geräteliste',
    'drag_drop_qr': 'Drag-and-Drop QR-Code',
    'enter_details': 'Gerätedetails eingeben',
    'device_code_required': 'Bitte Gerätecode eingeben',
    'device_code_present': 'Gerätecode bereits vorhanden',
    'device_name_required': 'Bitte geben Sie den Gerätenamen an',
    'device_name_present': 'Gerätename ist bereits vorhanden',
    'device_ip_required': 'Bitte IP-Adresse eingeben',
    'device_ip_not_valid': 'Bitte geben Sie eine gültige IP-Adresse an',
    'device_hostname_not_valid': 'Ungültiger Hostname',
    'device_ip_present': 'Geräte-IP-Adresse ist bereits vorhanden',
    'device_hostname_present': 'Hostname existiert bereits',
    'search_placeholder': 'Suche nach Gerätenamen, Code oder Benutzernamen',
    'device_name': 'Gerätename',
    'device_code': 'Gerätecode',
    'no_devices_found': 'Keine Geräte gefunden',
    'user_name': 'Benutzername',
    'deactivate': 'Deaktivieren',
    'deactivation_confirmed': 'Deaktivierung bestätigt',
    'deactivation_requested': 'Deaktivierung angefordert',
    'confirm_title': 'Gerät deaktivieren',
    'confirm_content': 'Möchten Sie das Gerät <b>{device_name}</b> wirklich deaktivieren?',
    'message_title': 'Anfrage bestätigt',
    'message_content': 'Wir haben Ihre Anfrage für das Gerät <b>{device_name}</b> erhalten. Unser Vertreter wird Sie innerhalb von 48 Stunden zur Deaktivierung anrufen.',
    'deactivate_success': 'Erfolgreich deaktiviert',
    'cancel': 'Abbrechen',
    'okay': 'Okay',
    'yes': 'Ja',
    'add_new_device_success': 'Neues Gerät erfolgreich hinzugefügt',
    'ip_address': 'IP',
    'ip_address_type': 'IP-Typ',
    'ip_static': 'Statische IP',
    'ip_dynamic': 'Dynamische IP',
    'external_ip_dynamic': 'Externe dynamische IP',
    'internal_ip_dynamic': 'Interne dynamische IP',
    'ip_address_hostname': 'IP / Hostname',
    'dyndns': 'DynDNS',
    'hostname': 'Hostname',
    'edit_device': 'Gerät bearbeiten',
    'update': 'Aktualisieren',
    'update_device_success': 'Das Gerät wurde erfolgreich aktualisiert',
    'select_device_state': 'Gerätezustand auswählen',
    'active': 'Aktiv',
    'all_devices': 'Alle Geräte',
    'deactivated': 'Deaktiviert',
    'remove_device_success': 'Gerät erfolgreich entfernt',
    'group_repeated': 'Dieser Name ist bereits vergeben',
    'status': 'Status',
    'last_connection': 'Letzte Verbindung',
    'delete_device': 'Gerät löschen',
    'disconnected': 'Getrennt',
    'disconnected_devices': 'Getrennte Geräte',
    'connected_devices': 'Verbundene Geräte',
    'hostname_public_to_internet': 'Hostnamen im Internet veröffentlichen'
  },
  'policy': {
    'cancel': 'Stornieren',
    'edit_policy': 'Richtlinie bearbeiten',
    'name': 'Name',
    'all_policies': 'Alle Richtlinien',
    'policy_name_required': 'Richtlinienname erforderlich',
    'policy_name_present': 'Der Name der Richtlinie existiert bereits',
    'update': 'Aktualisieren',
    'update_policy_success': 'Die Richtlinie wurde erfolgreich aktualisiert',
    'create_policy': 'Richtlinie erstellen',
    'select_policy': 'Richtlinie auswählen',
    'productivity': 'Produktive Tätigkeit',
    'geo_blocking': 'Geoblocking',
    'geo_blocking_rules_list': 'Regionen',
    'allow': 'Zulassen',
    'block': 'Blockieren',
    'protection': 'Schutz',
    'custom_config': 'Benutzerdefinierte Konfiguration',
    'categories_list': 'Kategorien',
    'go_to_easy_config': 'Zur einfachen Konfiguration wechseln',
    'system_blacklist': 'System Schwarze Liste',
    'choose_policy': 'WÄHLEN SIE EINE UNSERER VORDEFINIERTEN SICHERHEITSRICHTLINIEN',
    'base': 'Einfach',
    'medium': 'Mittel',
    'high': 'Hoch',
    'custom': 'Benutzerdefiniert',
    'add_placeholder': 'youtube.com',
    'search_label': 'Suche',
    'search_placeholder': 'Zum suchen tippen',
    'personal_blacklist': 'Gesperrte Domänen',
    'personal_whitelist': 'Zugelassene Domänen',
    'search_engine_protection': 'Suchmaschinen',
    'search_engines': 'Schutz der Google®- und Bing®-Suche',
    'search_engine_tooltip': 'Hinweis: Schalten Sie die sichere Suche von Google/Bing ein, um die Suchergebnisse (Webseiten, Bilder und Inhalte) einzuschränken, es werden nur sichere und geeignete Ergebnisse angezeigt. Pornografie und andere unerwünschte Inhalte werden nicht in die Suchergebnisse aufgenommen.',
    'blocked_categories': 'Blockierte Kategorien',
    'successfully_changed_config': 'Erfolgreiche Änderung der Konfiguration nach',
    'empty_domain': 'Bitte geben Sie eine Domäne an',
    'domain_already_added': 'Die eingegebene Domäne wurde bereits hinzugefügt',
    'invalid_domain': 'Bitte geben Sie eine gültige Domäne an',
    'successfully_added_to_whitelist': '\'{siteName}\' wurde erfolgreich zur Whitelist hinzugefügt',
    'successfully_removed_from_whitelist': '\'{siteName}\' wurde erfolgreich von der Whitelist entfernt',
    'successfully_added_to_blacklist': '\'{siteName}\' wurde erfolgreich zur Blacklist hinzugefügt',
    'successfully_removed_from_blacklist': '\'{siteName}\' wurde erfolgreich von der Blacklist entfernt',
    'successfully_added_to_productivitylist': '\'{siteName}\' wurde erfolgreich zu meiner unternehmensspezifischen Kategorie hinzugefügt',
    'successfully_removed_from_productivitylist': '\'{siteName}\' wurde erfolgreich aus meiner unternehmensspezifischen Kategorie entfernt',
    'select_device_message': 'Bitte wählen Sie ein Gerät aus, um fortzufahren',
    'enabled_search_engine_protection': 'Suchmaschinenschutz aktiviert',
    'disabled_search_engine_protection': 'Suchmaschinenschutz deaktiviert',
    'selected_device_is_deactivated': 'Das ausgewählte Gerät ist deaktiviert. Bitte wählen Sie ein anderes Gerät.',
    'legend': 'Legende'
  },
  'categories': {
    'category_business': 'Unternehmensspezifische Kategorie',
    'ads_spam_web_stats': 'Werbung, Spam und Webstatistiken',
    'advertising': 'Werbung',
    'domain_for_sale': 'Domäne zum Verkauf',
    'e_commerce': 'E-Commerce',
    'job_search': 'Jobsuche',
    'web_stats': 'Webstatistiken',
    'audio_video': 'Audio & Video',
    'amazon_prime_video': 'Amazon Prime Video',
    'netflix': 'Netflix',
    'others_audio_video': 'Andere Audio- & Videoanbieter',
    'spotify': 'Spotify',
    'youtube': 'YouTube',
    'dangerous': 'Gefährlich',
    'coronavirus_frauds': 'Coronavirus-Betrug',
    'gambling': 'Glücksspiel',
    'p2p_and_filesharing': 'P2P und Filesharing',
    'proxy': 'Proxy',
    'tools_for_hacking': 'Hacker Tools',
    'blog': 'Blog',
    'books': 'Bücher',
    'fashion': 'Mode',
    'free_time': 'Freizeit',
    'music': 'Musik',
    'pets': 'Haustiere',
    'photography': 'Fotografie',
    'radio': 'Radio',
    'religion': 'Religion',
    'shopping': 'Einkaufen',
    'shows_vips': 'Shows & VIPs',
    'sport': 'Sport',
    'travel': 'Reise',
    'generic_safe_websites': 'Allgemeine sichere Websites',
    'malware_threats': 'Virenbedrohung',
    'backdoor': 'Hintertür',
    'botnet': 'Botnet',
    'dangerous_threats': 'Gefährliche Bedrohungen',
    'malicious_activity': 'Bösartige Aktivität',
    'malicious_dns_activity': 'Schädliche DNS-Aktivität',
    'malicious_web_sites': 'Bösartige Webseiten',
    'malware_community': 'Malware Community',
    'phishing': 'Phishing',
    'phishing_community': 'Phishing Community',
    'ransomware_and_cryptowall': 'Ransomware und Cryptowall',
    'suspicious_web_activity': 'Verdächtige Webaktivität',
    'trojan': 'Trojaner',
    'worm': 'Wurm',
    'news': 'Nachrichten',
    'search_engines': 'Suchmaschinen',
    'social_network': 'Soziales Netzwerk',
    'facebook': 'Facebook',
    'instagram': 'Instagram',
    'other_social_networks': 'Andere soziale Netzwerke',
    'tiktok': 'TikTok',
    'twitter': 'Twitter',
    'whatsapp_vocals_calls_only': 'WhatsApp (nur Sprachnachrichten und Anrufe)',
    'technology': 'Technologie',
    'cdn': 'CDN',
    'file_hosting': 'Datei-Hosting',
    'iana_domains_tld_and_arpa': 'IANA-Domänen, TLD und ARPA',
    'instant_messaging': 'Instant Messaging',
    'online_services': 'Online-Dienste',
    'sip_provider': 'SIP-Anbieter',
    'software_tech': 'Software und Technologie',
    'updates': 'Aktualisierung',
    'web_mail': 'Web Mail',
    'unwanted_websites': 'Unerwünschte Websites',
    'adult': 'Erwachsene',
    'chat': 'Chat',
    'colombian_government_blacklist': 'Blacklist der kolumbianischen Regierung',
    'dating': 'Dating',
    'desktop_wallpapers': 'Desktop-Hintergründe',
    'dns_proxy': 'DNS-Proxy',
    'drugs': 'Drogen',
    'games': 'Spiele',
    'online_auctions': 'Online-Auktionen',
    'porn': 'Pornographie',
    'sexuality': 'Sexualität',
    'weapons': 'Waffen',
    'work': 'Arbeit',
    'associations': 'Verbände',
    'companies': 'Unternehmen',
    'education_training': 'Bildung und Ausbildung',
    'financial': 'Finanzen',
    'legal': 'Recht',
    'medical': 'Medizin',
    'pa_government': 'Palästinensische Behörde und Regierung',
    'others': 'Andere',
    'uncategorized': 'Nicht kategorisiert',
    'ads': 'Werbung',
    'parked_domains': 'Geparkte Domänen',
    'web_advertisements': 'Web-Anzeigen',
    'hacking': 'Hacking',
    'open_http_proxies': 'Freie HTTP-Proxies',
    'pay_to_surf': 'Surfen gegen Bezahlung',
    'peer_to_peer': 'Peer to Peer',
    'local_information': 'Lokale Informationen',
    'abortion': 'Abtreibung',
    'military': 'Militär',
    'computer_and_internet_info': 'Computer- und Internetnachrichten',
    'entertainment_and_arts': 'Unterhaltung und Kunst',
    'fashion_and_beauty': 'Mode und Kosmetik',
    'food_and_dining': 'Essen und Trinken',
    'home_and_garden': 'Haus und Garten',
    'hunting_and_fishing': 'Jagen und Fischen',
    'internet_portals': 'Internet-Portale',
    'kids': 'Inhalt für Kinder',
    'motor_vehicles': 'Kraftfahrzeuge',
    'online_greeting_cards': 'Grußkarten',
    'personal_sites_and_blogs': 'Blogs und persönliche Websites',
    'philosophy_and_political_advocacy': 'Philosophie und Politik',
    'real_estate': 'Immobilien',
    'recreation_and_hobbies': 'Freizeit und Hobbys',
    'society': 'Gesellschaft',
    'sports': 'Sport',
    'bot_nets': 'Bot Nets',
    'confirmed_spam_sources': 'Bestätigte SPAM-Quellen',
    'keyloggers_and_monitoring': 'Keylogger und Überwachung',
    'malware_sites': 'Malware-Sites',
    'phishing_and_other_frauds': 'Phishing und anderer Betrug',
    'proxy_avoidance_and_anonymizers': 'Versteckte Proxys und Anonymisierer',
    'spam_urls': 'Spam URLs',
    'spyware_and_adware': 'Spyware und Adware',
    'unconfirmed_spam_sources': 'Unbestätigte SPAM-Quellen',
    'media': 'Multimedia',
    'news_and_media': 'Nachrichten und Medien',
    'social_networking': 'Soziale Netzwerke',
    'streaming_media': 'Streaming-Inhalte',
    'image_and_video_search': 'Bild- und Videosuchmaschinen',
    'computer_and_internet_security': 'Computer- und Internetschutz',
    'content_delivery_networks': 'Content Delivery Network',
    'dynamically_generated_content': 'Dynamisch generierte Inhalte',
    'individual_stock_advice_and_tools': 'Individuelle Aktienberatung und Hilfsmittel',
    'internet_communications': 'Internet-Kommunikation',
    'personal_storage': 'Persönlicher Speicher',
    'shareware_and_freeware': 'Shareware und Freeware',
    'translation': 'Übersetzung',
    'web_hosting': 'Web Hosting',
    'web_based_email': 'Web Email',
    'abused_drugs': 'Drogen',
    'adult_and_pornography': 'Inhalte für Erwachsene und Pornografie',
    'alcohol_and_tobacco': 'Alkohol und Tabak',
    'auctions': 'Auktionen',
    'cheating': 'Betrug',
    'cult_and_occult': 'Kult und Okkultismus',
    'dead_sites': 'Inaktive Websites',
    'gross': 'Abstoßende Inhalte',
    'hate_and_racism': 'Hass und Rassismus',
    'illegal': 'Illegale Inhalte',
    'marijuana': 'Marihuana',
    'Military': 'Militär',
    'nudity': 'Nacktheit',
    'private_ip_addresses': 'Private IP-Adressen',
    'sex_education': 'Sexualerziehung',
    'swimsuits_and_intimate_apparel': 'Badebekleidung und Unterwäsche',
    'violence': 'Gewalt',
    'business_and_economy': 'Unternehmen und Wirtschaft',
    'educational_institutions': 'Bildungseinrichtungen',
    'financial_services': 'Finanzdienstleistungen',
    'government': 'Regierung',
    'health_and_medicine': 'Gesundheit und Medizin',
    'reference_and_research': 'Forschung und Bibliografie',
    'training_and_tools': 'Pädagogische Hilfsmittel',
    'self_harm': 'Selbstverletzung',
    'dns_over_https': 'DNS über HTTPS',
    'low_thc_cannabis_products': 'Cannabisprodukte mit niedrigem THC-Gehalt',
    'generative_ai': 'Generative KI'
  },
  'regions': {
    "afghanistan": "Afghanistan",
    "africa": "Afrika",
    "aland_islands": "Aland-Inseln",
    "albania": "Albanien",
    "algeria": "Algerien",
    "american_samoa": "Amerikanisch-Samoa",
    "andorra": "Andorra",
    "angola": "Angola",
    "anguilla": "Anguilla",
    "anonymous_proxy": "Anonymer Proxy",
    "antarctica_(the_territory_south_of_60_deg_s)": "Antarktis (das Gebiet südlich des 60. Breitengrades)",
    "antarctica": "Antarktis",
    "antigua_and_barbuda": "Antigua und Barbuda",
    "argentina": "Argentinien",
    "armenia": "Armenien",
    "aruba": "Aruba",
    "asia_pacific_region": "asiatisch-pazifischer Raum",
    "asia": "Asien",
    "australia": "Australien",
    "austria": "Österreich",
    "azerbaijan": "Aserbaidschan",
    "bahamas": "Bahamas",
    "bahrain": "Bahrain",
    "baltics": "Baltikum",
    "bangladesh": "Bangladesch",
    "barbados": "Barbados",
    "belarus": "Weißrussland",
    "belgium": "Belgien",
    "belize": "Belize",
    "benin": "Benin",
    "bermuda": "Bermuda",
    "bhutan": "Bhutan",
    "bolivia": "Bolivien",
    "bonaire_saint_eustatius_and_saba": "Bonaire St. Eustatius und Saba",
    "bonaire": "Bonaire",
    "bosnia_and_herzegovina": "Bosnien und Herzegowina",
    "botswana": "Botswana",
    "bouvet_island_(bouvetoya)": "Bouvetinsel (Bouvetoya)",
    "brazil": "Brasilien",
    "british_indian_ocean_territory_(chagos_archipelago)": "Britisches Territorium im Indischen Ozean (Chagos-Archipel)",
    "british_indian_ocean_territory": "Britisches Territorium im Indischen Ozean",
    "british_virgin_islands": "Britische Jungferninseln",
    "brunei_darussalam": "Brunei Darussalam",
    "bulgaria": "Bulgarien",
    "burkina_faso": "Burkina Faso",
    "burundi": "Burundi",
    "cambodia": "Kambodscha",
    "cameroon": "Kamerun",
    "canada": "Kanada",
    "cape_verde": "Kap Verde",
    "cayman_islands": "Kaimaninseln",
    "central_african_republic": "Zentralafrikanische Republik",
    "chad": "Chad",
    "chile": "Chile",
    "china": "China",
    "christmas_island": "Weihnachtsinsel",
    "cocos_(keeling)_islands": "Kokosinseln (Keeling)",
    "colombia": "Kolumbien",
    "comoros": "Komoren",
    "congo_the_democratic_republic_of_the": "Demokratische Republik Kongo",
    "congo": "Kongo",
    "cook_islands": "Cookinseln",
    "costa_rica": "Costa Rica",
    "cote_d_ivoire": "Cote d'Ivoire (Elfenbeinküste)",
    "croatia": "Kroatien",
    "cuba": "Kuba",
    "curacao": "Curaçao",
    "cyprus": "Zypern",
    "czech_republic": "Tschechische Republik",
    "denmark": "Dänemark",
    "disputed_territory": "Umstrittenes Territorium",
    "djibouti": "Dschibuti",
    "dominica": "Dominica",
    "dominican_republic": "Dominikanische Republik",
    "eastern_europe": "Ost-Europa",
    "ecuador": "Ecuador",
    "egypt": "Ägypten",
    "el_salvador": "El Salvador",
    "equatorial_guinea": "Äquatorial-Guinea",
    "eritrea": "Eritrea",
    "estonia": "Estland",
    "ethiopia": "Äthiopien",
    "europe": "Europe",
    "falkland_islands_(malvinas)": "Falklandinseln (Malwinen)",
    "faroe_islands": "Faröer-Inseln",
    "fiji": "Fidschi",
    "finland": "Finnland",
    "france": "Frankreich",
    "french_guiana": "Französisch-Guayana",
    "french_polynesia": "Französisch-Polynesien",
    "french_southern_territories": "Französische Süd-Territorien",
    "gabon": "Gabun",
    "gambia": "Gambia",
    "georgia": "Georgien",
    "germany": "Deutschland",
    "ghana": "Ghana",
    "gibraltar": "Gibraltar",
    "greece": "Griechenland",
    "greenland": "Grönland",
    "grenada": "Grenada",
    "guadeloupe": "Guadeloupe",
    "guam": "Guam",
    "guatemala": "Guatemala",
    "guernsey": "Guernsey",
    "guinea_bissau": "Guinea-Bissau",
    "guinea": "Guinea",
    "guyana": "Guyana",
    "haiti": "Haiti",
    "heard_island_and_mcdonald_islands": "Heard-Insel und McDonald-Inseln",
    "holy_see_(vatican_city_state)": "Heiliger Stuhl (Staat Vatikanstadt)",
    "honduras": "Honduras",
    "hong_kong": "Hong Kong",
    "hungary": "Ungarn",
    "iceland": "Island",
    "india": "Indien",
    "indonesia": "Indonesien",
    "ip_not_defined": "IP nicht definiert",
    "iran_islamic_republic_of": "Iran, Islamische Republik",
    "iran": "Iran",
    "iraq_saudi_arabia_neutral_zone": "Neutrale Zone Irak-Saudi-Arabien",
    "iraq": "Irak",
    "ireland": "Irland",
    "isle_of_man": "Isle of Man",
    "israel": "Israel",
    "italy": "Italien",
    "jamaica": "Jamaica",
    "japan": "Japan",
    "jersey": "Jersey",
    "jordan": "Jordanien",
    "kazakhstan": "Kasachstan",
    "kenya": "Kenia",
    "kiribati": "Kiribati",
    "north_korea": "Nordkorea",
    "south_korea": "Südkorea",
    "korea": "Korea",
    "kuwait": "Kuwait",
    "kyrgyz_republic": "Kirgisische Republik",
    "kyrgyzstan": "Kirgisistan",
    "lao_people's_democratic_republic": "Demokratische Volksrepublik Laos",
    "latin_america_and_caribbean": "Lateinamerika und Karibik",
    "latvia": "Lettland",
    "lebanon": "Libanon",
    "lesotho": "Lesotho",
    "liberia": "Liberia",
    "libya": "Libyen",
    "libyan_arab_jamahiriya": "Libysch-Arabische Dschamahirija",
    "liechtenstein": "Liechtenstein",
    "lithuania": "Litauen",
    "luxembourg": "Luxemburg",
    "macao": "Macao",
    "macau": "Macau",
    "macedonia": "Mazedonien",
    "madagascar": "Madagaskar",
    "malawi": "Malawi",
    "malaysia": "Malaysia",
    "maldives": "Malediven",
    "mali": "Mali",
    "malta": "Malta",
    "marshall_islands": "Marshallinseln",
    "martinique": "Martinique",
    "mauritania": "Mauretanien",
    "mauritius": "Mauritius",
    "mayotte": "Mayotte",
    "mexico": "Mexiko",
    "micronesia_federated_states_of": "Vereinigte Staaten von Mikronesien",
    "micronesia": "Mikronesien",
    "middle_east": "Naher Osten",
    "moldova_republic_of": "Republik Moldau",
    "moldova": "Moldawien",
    "monaco": "Monaco",
    "mongolia": "Mongolei",
    "montenegro": "Montenegro",
    "montserrat": "Montserrat",
    "morocco": "Marokko",
    "mozambique": "Mosambik",
    "myanmar": "Myanmar",
    "namibia": "Namibia",
    "nauru": "Nauru",
    "nepal": "Nepal",
    "netherlands_antilles": "Niederländische Antillen",
    "netherlands": "Niederlande",
    "new_caledonia": "Neukaledonien",
    "new_zealand": "Neuseeland",
    "nicaragua": "Nicaragua",
    "niger": "Niger",
    "nigeria": "Nigeria",
    "niue": "Niue",
    "norfolk_island": "Norfolkinsel",
    "north_africa": "Nordafrika",
    "north_america": "Nord-Amerika",
    "northern_mariana_islands": "Nördliche Marianen",
    "norway": "Norwegen",
    "oceania": "Ozeanien",
    "oman": "Oman",
    "pakistan": "Pakistan",
    "palau": "Palau",
    "palestinian_territory": "Palästinensisches Autonomiegebiet",
    "panama": "Panama",
    "papua_new_guinea": "Papua-Neuguinea",
    "paraguay": "Paraguay",
    "peru": "Peru",
    "philippines": "Philippinen",
    "pitcairn_islands": "Pitcairninseln",
    "poland": "Polen",
    "portugal": "Portugal",
    "puerto_rico": "Puerto Rico",
    "qatar": "Katar",
    "reunion": "Reunion",
    "romania": "Rumänien",
    "russia_and_c_w_of_ind_states": "Russland und Gemeinschaft Unabhängiger Staaten (GUS)",
    "russian_federation": "Russische Föderation",
    "rwanda": "Ruanda",
    "saint_barthelemy": "St. Barthelemy",
    "saint_helena": "St. Helena",
    "saint_kitts_and_nevis": "St. Kitts und Nevis",
    "saint_lucia": "St. Lucia",
    "saint_martin": "St. Martin",
    "saint_pierre_and_miquelon": "St. Pierre und Miquelon",
    "saint_vincent_and_the_grenadines": "St. Vincent und die Grenadinen",
    "samoa": "Samoa",
    "san_marino": "San Marino",
    "sao_tome_and_principe": "Sao Tome und Principe",
    "satellite_connections": "Satellitenverbindungen",
    "satellite_provider": "Satellitenanbieter",
    "saudi_arabia": "Saudi-Arabien",
    "senegal": "Senegal",
    "serbia": "Serbien",
    "seychelles": "Seychellen",
    "sierra_leone": "Sierra Leone",
    "singapore": "Singapur",
    "sint_maarten_dutch_part": "St. Maarten Niederländischer Teil",
    "sint_maarten_(netherlands)": "St. Maarten (Niederlande)",
    "slovakia_(slovak_republic)": "Slowakei (Slowakische Republik)",
    "slovakia": "Slowakei",
    "slovenia": "Slowenien",
    "solomon_islands": "Salomoninseln",
    "somalia": "Somalia",
    "south_africa": "Südafrika",
    "south_america": "Südamerika",
    "south_georgia_and_the_south_sandwich_islands": "Südgeorgien und die Südlichen Sandwichinseln",
    "south_sudan": "Südsudan",
    "spain": "Spanien",
    "spratly_islands": "Spratly-Inseln",
    "sri_lanka": "Sri Lanka",
    "sub_saharan_africa": "Sub-Sahara-Afrika",
    "sudan": "Sudan",
    "suriname": "Surinam",
    "svalbard_and_jan_mayen": "Svalbard und Jan Mayen",
    "svalbard_jan_mayen_islands": "Svalbard & Jan Mayen Inseln",
    "swaziland": "Swasiland",
    "sweden": "Schweden",
    "switzerland": "Schweiz",
    "syrian_arab_republic": "Arabische Republik Syrien",
    "taiwan": "Taiwan",
    "tajikistan": "Tadschikistan",
    "tanzania": "Tansania",
    "thailand": "Thailand",
    "timor_leste": "Timor-Leste",
    "togo": "Togo",
    "tokelau": "Tokelau",
    "tonga": "Tonga",
    "trinidad_and_tobago": "Trinidad und Tobago",
    "tunisia": "Tunesien",
    "turkey": "Türkei",
    "turkmenistan": "Turkmenistan",
    "turks_and_caicos_islands": "Turks- und Caicosinseln",
    "tuvalu": "Tuvalu",
    "uganda": "Uganda",
    "ukraine": "Ukraine",
    "united_arab_emirates": "Vereinigte Arabische Emirate",
    "united_kingdom_of_great_britain_&_northern_ireland": "Vereinigtes Königreich von Großbritannien und Nordirland",
    "united_kingdom": "Vereinigtes Königreich",
    "united_nations_neutral_zone": "Neutrale Zone der Vereinten Nationen",
    "united_states_minor_outlying_islands": "Vereinigte Staaten Minor Outlying Islands",
    "united_states_of_america": "Vereinigte Staaten von Amerika",
    "united_states_virgin_islands": "Vereinigte Staaten Jungferninseln",
    "united_states": "Vereinigte Staaten",
    "uruguay": "Uruguay",
    "uzbekistan": "Usbekistan",
    "vanuatu": "Vanuatu",
    "venezuela": "Venezuela",
    "vietnam": "Vietnam",
    "virgin_islands_british": "Britische Jungferninseln",
    "virgin_islands_u_s": "Jungferninseln, U.S.",
    "wallis_and_futuna": "Wallis und Futuna",
    "western_sahara": "Westsahara",
    "yemen": "Jemen",
    "zambia": "Sambia",
    "zimbabwe": "Simbabwe",
  },
  'profile': {
    'first_name': 'Vorname',
    'last_name': 'Nachname',
    'email': 'Email',
    'no_of_devices': 'Anzahl der hinzugefügten Geräte',
    'devices': 'Geräte',
    'support': 'Unterstützung',
    'details_support': 'Kontaktdetails',
    'reseller_token': 'Reseller-Token',
    'manual': 'Handbuch',
    'current_password': 'Aktuelles passwort',
    'manual_url': 'https://storage.googleapis.com/bssdownloads/Dm953-I-BeSafeSmartUserManual-De.pdf'
  },
  'role': {
    'account_managers': 'Administratoren',
    'reseller_manager': 'Reseller-Administrator',
    'reseller_managers': 'Reseller-Administratoren',
    'end_users': 'Endanwender',
    'account_manager': 'Administrator',
    'end_user': 'Endanwender',
    'role': 'Rolle',
    'reseller_user': 'Reseller Benutzer',
    'resellers': 'Reseller Benutzer'
  },
  'users': {
    'create_user': 'Benutzer erstellen',
    'no_users_found': 'Keine Benutzer gefunden',
    'search_placeholder': 'Suche nach Benutzername oder E-Mail',
    'delete': 'Benutzer löschen',
    'confirm_delete': 'Diese Aktion ist endgültig. Wollen Sie fortfahren?'
  },
  'productivity': {
    'no_category_found': 'Keine Kategorien gefunden',
    'search_placeholder': 'Suche nach Kategorie'
  },
  'administration': {
    'create_demo_organization': 'Demo-Kunde erstellen',
    'create_reseller': 'Händler erstellen',
    'name': 'Nombre',
    'empty_name': 'Fehler: Leerer Name',
    'invalid_repeated_name': 'Fehler: Name bereits registriert',
    'phone': 'Telefon',
    'contact': 'Kontakt',
    'search_by_name': 'Suche nach Namen...',
    'delete_reseller': 'Händler löschen',
    'create_organization': 'Kunden erstellen',
    'delete_account': 'Kunden löschen',
    'successfully': 'Erfolgreich',
    'organization': 'Kunde',
    'reseller': 'Händler',
    'hours': 'Geschäftszeiten',
    'openning_hours': 'Mo-Fr 08:00-15:00'
  },
  'accounts': {
    'accounts': 'Kunden',
    'create_account': 'Erstellen Sie einen neuen Kunden',
    'name_account': 'Name des Kunden',
    'account_name_required': 'Geben Sie den Namen des Kunden an',
    'create_end_user': 'Neuen Benutzer anlegen',
    'total_licenses': 'Lizenzen insgesamt',
    'create_end_user': 'Benutzer erstellen',
    'new_licenses': 'Neue Lizenzen',
    'create_account_success': 'Kunden erfolgreich erstellt',
    'create_account_manager': 'Erstellen Sie einen neuen Account Manager',
    'available_licenses': 'Verfügbare Lizenzen',
    'select_account': 'Kunden auswählen',
    'license_type': 'Art der Lizenz',
    'edit_licenses': 'Lizenzen hinzufügen',
    'edit': 'Kunden bearbeiten',
    'search_placeholder': 'Suche nach Kontoname'
  },
  'resellers': {
    'delete_success': 'Händler erfolgreich gelöscht',
    'delete_error': 'Fehler beim Löschen eines Händlers'
  },
  'licenses': {
    'licenses': 'Lizenzen',	
    'license': 'Lizenz',
    'expiration_license': 'Ablauf der Lizenz'
  },
  'subscription': {
    'billing_address_not_found': 'Rechnungsadresse nicht gefunden',
    'status': 'Status',
    'license_used': 'Lizenz verwendet',
    'licenses_available': 'Lizenzen verfügbar',
    'price': 'Preis',
    'invoices': 'Rechnungen',
    'next_billing_date': 'Nächstes Abrechnungsdatum',
    'edit_payment_method': 'Zahlungsmethode bearbeiten',
    'cancel_subscription': 'Abonnement kündigen',
    'cancel_subscription_success': 'Abonnement erfolgreich gekündigt',
    'reactivate_subscription': 'Abonnement reaktivieren',
    'reactivate_subscription_success': 'Abonnement erfolgreich reaktiviert',
    'ending_in': 'endet in',
    'expires': 'läuft ab',
    'payment_method': 'Zahlungsmethode',
    'expiration_date': 'Ablaufdatum',
    'terms_and_conditions': 'Geschäftsbedingungen',
    'accepted_on': 'angenommen am',
    'amount': 'Betrag',
    'date': 'Datum',
    'confirm_cancel': 'Möchten Sie das Abonnement kündigen?',
    'update_payment': 'Zahlungsmethode aktualisieren',
    'update_payment_success': 'Zahlungsmethode erfolgreich aktualisiert'
  },
  'helpers': {
    'device_name': 'Der Gerätename wird verwendet, um jedes Gerät zu identifizieren',
    'device_code': 'Der Gerätecode ist die Seriennummer Ihres Geräts',
    'ip_type': '<li><b>IP statisch:</b> Verwenden Sie diese Option, wenn Ihr Gerät eine statische IP hat (z. B.: 85.24.63.13).</li><li><b>Externe dynamische IP:</b>Verwenden diese Option, wenn Ihr Gerät eine dynamische IP hat und Sie bereits einen externen Dyndns-Dienst nutzen (z. B.: gerät_01.yourcompany.com)</li><li><b>Interne dynamische IP:</b>Verwenden Sie diese Option, wenn Ihr Gerät über eine dynamische IP verfügt und Sie keinen externen Dyndns-Dienst haben. Sie können einen Hostnamen mit \‘*.cloudteldat.com\’ als interne Domäne registrieren.</li>',
    'switch': '<li>Einschalten: Datenverkehr erlaubt</li><li>Ausschalten: Datenverkehr gesperrt</li>'
  }
}
