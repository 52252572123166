<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style src="./style.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"
></style>
<style src="@/vendor/styles/loader.scss" lang="scss"></style>
<style src="@/vendor/libs/spinkit/spinkit.scss" lang="scss"></style>

<script>
  import { mapGetters } from "vuex";
  // eslint-disable-next-line
  var APP_ID = "fh84almi";
  // eslint-disable-next-line
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + APP_ID;
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();

  export default {
    name: "App",
    metaInfo: {
      title: "Index",
      titleTemplate: "%s | beSafe",
    },
    data() {
      return {
        isScrolledEnough: false,
      };
    },
    computed: {
      ...mapGetters(["authUser", "fetchTheme", "themeName", "isThemeLoading"]),
    },
    watch: {
      authUser() {
        this.loadIntercom();
      },
      themeName() {
        this.loadTheme();
      },
    },
    updated() {
      // Remove loading state
      setTimeout(() => document.body.classList.remove("app-loading"), 1);
    },
    beforeMount() {
      this.$store.dispatch("fetchTheme");
    },
    mounted() {
      this.loadTheme();
    },
    updated() {
      setTimeout(() => {
        document.body.classList.remove("app-loading");
        this.loadIntercom();
      }, 1);
    },
    created() {
      window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
      loadIntercom() {
        if (this.authUser && this.authUser?.role == "end_user") {
          window.Intercom("boot", {
            app_id: "fh84almi",
          });
        }
      },
      handleScroll(e) {
        this.isScrolledEnough = window.scrollY > 0;
      },
      loadTheme() {
        if (this.themeName) {
          var oldLink = document.querySelector(".theme-settings-theme-css")
          var head = document.getElementsByTagName("head")[0];
          let link = document.createElement("link");
          link.type = "text/css";
          link.title = this.themeName;
          link.rel = "stylesheet";
          link.className = "theme-settings-theme-css";
          link.href = `css/vendor/theme-${this.themeName}-material.css`;

          head.appendChild(link);

          if (oldLink) {
            head.removeChild(oldLink)
          }
          
        }
      },
    },
  };
</script>
