<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }" @mouseleave="mouseLeave">
      <sidenav-router-link v-if="isActiveDevice && !isReseller" icon="ion ion-md-apps" to="/" :exact="true">{{ $t('sidebar.dashboard') }}</sidenav-router-link>
      <sidenav-menu v-if="canSeeAdministrationMenu" icon="ion ion-md-people" menu-class="bg-primary-dark" ref="menu-admin">
        <template #link-text>
          {{ $t('sidebar.administration') }}
        </template>
        <b-dropdown-item
          v-if="canSeeResellersSubMenu"
          link-class="text-white ml-4"
          to="/resellers"
        >
          <span class="ion ion-ios-git-network rotate-180" style="margin-right: 10px" />
          {{ $t('sidebar.resellers') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeResellerUsersSubMenu"
          link-class="text-white ml-4"
          @click="navigateReseller"
        >
          <span class="ion ion-ios-git-network rotate-180" style="margin-right: 10px" />
          {{ $t('sidebar.users_reseller') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeAccountsSubMenu"
          link-class="text-white ml-4"
          to="/organizations"
        >
          <span class="ion ion-ios-contacts" style="margin-right: 10px" />
          {{ $t('accounts.accounts') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canSeeUsersSubMenu"
          link-class="text-white ml-4"
          @click="navigateUser"
        >
          <span class="ion ion-ios-contacts" style="margin-right: 10px" /> 
          {{ $t('sidebar.users') }}
        </b-dropdown-item>
      </sidenav-menu>
      <sidenav-router-link v-if="!isReseller" icon="ion ion-ios-apps" to="/devices" :exact="true">{{ $t('sidebar.devices') }}</sidenav-router-link>
      <sidenav-router-link v-if="isActiveDevice && !isReseller" icon="ion ion-ios-book" to="/configuration" :exact="true">{{ $t('sidebar.policy') }}</sidenav-router-link>
      <sidenav-router-link v-if="isActiveDevice && !isReseller" icon="ion ion-ios-pie" to="/reports" :exact="true">{{ $t('sidebar.reports') }}</sidenav-router-link>
      <sidenav-router-link class="d-block d-lg-none" icon="ion ion-ios-person" to="/profile" :exact="true">{{ $t('general.profile') }}</sidenav-router-link>
      <sidenav-router-link class="d-block d-lg-none" icon="far fa-envelope" to="/support" :exact="true">{{ $t('profile.support') }}</sidenav-router-link>
      <sidenav-menu class="d-block d-lg-none" icon="ion ion-ios-flag" menu-class="bg-primary-dark">
        <template #link-text>
          {{ $t('general.language') }}
        </template>
        <b-dropdown-item
          v-for="(langValue, langKey) in langMaps"
          :key="langKey"
          link-class="text-white ml-4"
          @click="setLang(langKey)"
        >
          <img
            class="mx-2"
            :src="`${baseUrl}img/flags/${langKey}.png`"
            alt
          > {{ langValue }}
        </b-dropdown-item>
      </sidenav-menu>
      <sidenav-link class="d-block d-lg-none" icon="ion ion-ios-log-out" menu-class="bg-primary-dark" @click="logOutUser">
        <span @click="logOutUser">{{ $t('general.logout') }}</span>
      </sidenav-link>
    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from '@/vendor/libs/sidenav'
import { mapGetters } from 'vuex'

export default {
  name: 'AppLayoutSidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical',
    },
  },

  data () {
    return {
      langMaps: { 'de': this.$t('language.german'), 'en': this.$t('language.english'), 'es': this.$t('language.spanish'),
      account: null
      }
    }
  },

  computed: {
    ...mapGetters([
      'canSeeAdministrationMenu', 'canSeeResellersSubMenu', 'canSeeResellerUsersSubMenu', 
      'canSeeAccountsSubMenu', 'canSeeUsersSubMenu', 'isReseller', 'isActiveDevice', 'authUser'
    ]),
    
    curClasses() {
      let bg = this.layoutSidenavBg

      if (
        this.orientation === 'horizontal' &&
        (bg.indexOf(' sidenav-dark') !== -1 ||
          bg.indexOf(' sidenav-light') !== -1)
      ) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0')
      )
    },
  },
  mounted(){
    this.getAccount()
    this.getReseller()
  },
  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0
    },
    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
      )
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },
    logOutUser () {
      this.$store.dispatch('logOut')
    },
    setLang (lang) {
      let that = this
      this.$http.patch('/users/update_locale', { locale: lang })
        .then(response => {
          that.$i18n.set(lang)
        }
      )
    },
    mouseLeave(){
      // close submenus
      this.$refs["menu-admin"].$el.classList.value = 'sidenav-item'
    },
    navigateUser(){
      this.$router.push({ name: "users-organization",params: { account: this.account } })
    },
    navigateReseller(){
      this.$router.push({ name: "users-reseller",params: { reseller: this.reseller } })
    },
    getAccount(){
      if (this.authUser.account_id != null) {
        this.$http.get('/accounts/' + this.authUser.account_id, { }).then(response => {
          this.account = response.data
        })
      } 
    },
    getReseller(){
      if (this.authUser.reseller_id != null) {
        this.$http.get('/resellers/' + this.authUser.reseller_id, { }).then(response => {
          this.reseller = response.data
        })
      } 
    }
  },
}


</script>
