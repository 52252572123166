import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'
import store from '@/store/store'

import globals from '@/globals'

// Layouts
import Layout1 from '@/layout/Layout1'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: "/",
  mode: "history",
  routes: [
    {
      path: "/",
      component: Layout1,
      children: [
        {
          path: "",
          name: "home",
          component: () => import("@/pages/Dashboard")
        },
        {
          name: "configuration",
          path: "configuration",
          component: () => import("@/pages/Configuration")
        },
        {
          name: "resellers",
          path: "resellers",
          component: () => import("@/pages/Resellers")
        },
        {
          name: "users-reseller",
          path: "users-reseller",
          component: () => import("@/pages/UsersReseller")
        },
        {
          name: "organizations",
          path: "organizations",
          component: () => import("@/pages/organizations/Organizations")
        },
        {
          name: "users-organization",
          path: "users-organization",
          component: () => import("@/pages/UserAccount")
        },
        {
          name: "invoices",
          path: "invoices",
          component: () => import("@/pages/Invoices")
        },
        {
          name: "reports",
          path: "reports",
          component: () => import("@/pages/reports/Reports")
        },
        {
          name: "devices",
          path: "devices",
          component: () => import("@/pages/devices/Devices")
        },
        {
          name: "profile",
          path: "profile",
          component: () => import("@/pages/Profile")
        },
        {
          name: "about",
          path: "about",
          component: () => import("@/pages/About")
        },
        {
          name: 'contact', path: 'contact_us',
          component: () => import('@/pages/Contact')
        },
        {
          name: 'subscription', path: 'subscription',
          component: () => import('@/pages/Subscription')
        },
        {
          name: 'support', path: 'support',
          component: () => import('@/pages/Support')
        }
      ]
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/pages/Login")
    },
    {
      name: "locked",
      path: "/locked",
      component: () => import("@/pages/locked/Locked"),
      props: route => { 
        const {query: { domain, device_ip: deviceIp, dns_index: dnsIndex, token, t } = {} } = route;
        return {
          domain,
          deviceIp,
          dnsIndex,
          token,
          t
        }
      }
    },
    {
      name: "forgot_password",
      path: "/forgot_password",
      component: () => import("@/pages/ForgotPassword")
    },
    {
      name: "set_password",
      path: "/set_password",
      component: () => import("@/pages/SetPassword")
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)

  let pagesAlwaysVisible = [
    "locked",
  ];
  let pagesNotRequiringLogin = [
    "login",
    "signup",
    "forgot_password",
    "set_password"
  ];
  let pagesNeedingActiveDevice = ["home", "configuration", "reports"];
  let resellerPages = ["users-reseller", "organizations", "profile", "support", "users-organization"];
  if (
    pagesNotRequiringLogin.indexOf(to.name) == -1 &&
    pagesAlwaysVisible.indexOf(to.name) == -1 &&
    !store.getters.isAuthenticated
  ) {
    next({ name: "login", query: { page: to.path } });
  } else if (
    pagesNotRequiringLogin.indexOf(to.name) >= 0 &&
    pagesAlwaysVisible.indexOf(to.name) == -1 &&
    store.getters.isAuthenticated
  ) {
    next({ name: "home" });
  } else if (
    store.getters.isAuthenticated &&
    resellerPages.indexOf(to.name) == -1 &&
    store.getters.authUser.role == 'reseller'
  ) {
    next({ name: 'organizations' })
  }
  else if (
    pagesNeedingActiveDevice.indexOf(to.name) >= 0 &&
    !store.getters.activeDevice
  ) {
    next({ name: "devices" });
  } else {
    if (to.name === 'subscription') {
      if (store.getters.authUser.role === 'account_manager') {
        next()
      } else {
        next({ name: 'home' })
      }
    } else {
      next()
    }
  }
});
export default router