// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import toasted from 'vue-toasted'
import BootstrapVue from 'bootstrap-vue'
import StarRating from 'vue-star-rating'

import globals from './globals'
import Popper from 'popper.js'

import { VueMasonryPlugin } from 'vue-masonry/index.js'
Vue.use(VueMasonryPlugin)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import HighchartsVue from 'highcharts-vue'
import HighCharts from 'highcharts'
import mapInit from 'highcharts/modules/map'

mapInit(HighCharts)
Vue.use(HighCharts)
Vue.use(HighchartsVue)

import DateRangePicker from 'vue-mj-daterangepicker'
import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
Vue.use(DateRangePicker)

import datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(datepicker)

import vuexI18n from 'vuex-i18n'
import English from './lang/en.js'
import Spanish from './lang/es.js'
import Deutsche from './lang/de.js'

Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('en', English)
Vue.i18n.add('es', Spanish)
Vue.i18n.add('de', Deutsche)
Vue.i18n.set('en')
Vue.i18n.fallback('de')

import './plugins/axiosConfig'
import './plugins/toasts'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(toasted)
Vue.component('star-rating', StarRating)
// Global RTL flag
Vue.mixin({
  data: globals,
})

export default new Vue({
  router,
  store,
  toasted,
  render: (h) => h(App),
}).$mount('#app')
