import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted)

Vue.toasted.register('warning', (payload) => { return payload }, {
  className: 'bg-warning text-dark',
  duration: 3000
})

Vue.toasted.register('success', (payload) => { return payload }, {
  className: 'bg-success',
  duration: 3000
})

Vue.toasted.register('error', (payload) => { return payload }, {
  className: 'bg-danger',
  duration: 3000
})
