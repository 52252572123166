import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import theme from './modules/theme'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default new Vuex.Store({
  modules: {
    user,
    theme,
  },
  plugins: [vuexLocal.plugin],
  state: {},
  mutations: {},
  actions: {},
  getters: {
    isAuthenticated: (state) => {
      return state.user.authUser != null
    },
  },
})
