<template>
  <div class="layout-footer footer">
    <div class="container-fluid container-p-x pb-3">
      <div class="text-right">
        Version: {{this.version}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLayoutFooter',
}
</script>
