import Vue from 'vue'
import axios from 'axios'
import router from '../../router'

export default {
  state: {
    authUser: null,
    activeDevice: null,
    activeAccount: null,
  },
  mutations: {
    authUser(state, data) {
      state.authUser = data
    },
    updateDevices(state, devices) {
      state.authUser.devices = devices
    },
    setActiveDevice(state, data) {
      state.activeDevice = data
    },
    setActiveAccount(state, data) {
      state.activeAccount = data
    },
  },
  getters: {
    authUser(state) {
      return state.authUser
    },
    activeDevice(state) {
      return state.activeDevice
    },
    isActiveDevice(state) {
      return state.activeDevice !== null
    },
    activeAccount(state) {
      return state.activeAccount
    },
    isActiveAccount(state) {
      return state.activeAccount !== null
    },
    isReseller(state){
      return state.authUser.role == 'reseller'
    },
    isAdvancedReseller(state){
      return state.authUser.role == 'reseller_manager'
    },
    isAccountManager(state){
      return state.authUser.role == 'account_manager'
    },
    isEndUser(state){
      return state.authUser.role == 'end_user'
    },
    canSeeOtherAccounts(state){
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager' || role == 'reseller'
    },
    canSeeAdministrationMenu(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager' || role == 'reseller' || role == 'account_manager' 
    },
    canSeeAccountsSubMenu(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager' || role == 'reseller'
    },
    canSeeResellersSubMenu(state) {
      const { authUser: { role }} = state
      return role == 'super_admin'
    },
    canSeeResellerUsersSubMenu(state) {
      const { authUser: { role }} = state
      return role == 'reseller_manager' || role == 'reseller'
    },
    canSeeUsersSubMenu(state) {
      const { authUser: { role }} = state
      return role == 'account_manager'
    },
    canSeeOtherAccountUsers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager' || role == 'reseller'
    },
    canSeeAccountUsers(state) {
      const { authUser: { role }} = state
      return role == 'account_manager'
    },
    canSeeAdvancedResellerUsers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager'
    },
    canManageResellers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager'
    },
    canManageOtherAccountsAndUsers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager'
    },
    canManageOtherDevices(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager'
    },
    canManageAccountManagers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager' || role == 'account_manager'
    },
    canManageEndUsers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager' || role == 'account_manager'
    },
    canManageResellerUsers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager'
    },
    canManageAdvancedResellerUsers(state) {
      const { authUser: { role }} = state
      return role == 'super_admin' || role == 'reseller_manager'
    }
  },
  actions: {
    async reloadDevices({ commit, state }, vueObj){
      let res = await axios.get('/devices')
      // var extractedDeviceDetails = res.data.map((e) => {
      //   return {
      //     id: e.id,
      //     alias_name: e.alias_name,
      //     searcsh_engine_protection: e.search_engine_protection,
      //     restriction_level: e.restriction_level
      //   }
      // })
      // let activeDeviceExists = false
      res.data.some((extractedDevice) => {
        if(state.activeDevice !== null && extractedDevice.id == state.activeDevice.id) {
          commit('setActiveDevice', extractedDevice)
        }
      })
      commit('updateDevices', res.data)
    },

    persist({ commit }, response) {
      if (response && (response.status == 200 || response.status == 201)) {
        response.data.devices.unshift({ id: 0, alias_name: 'general.all_devices' })
        commit('authUser', response.data)
        commit('setActiveDevice', response.data.devices[0])
        if (response.data.role == "super_admin" || response.data.role == "reseller_manager" || response.data.role == "reseller" || !response.data.account_id) {
          commit('setActiveAccount', { id: 0, name: 'general.all_accounts' })
        } else if (response.data.account) {
          commit('setActiveAccount', { id: response.data.account.id, name: response.data.account.name})
        }
        if (router.currentRoute.query.page !== undefined) {
          router.replace({ path: router.currentRoute.query.page })
        } else {
          router.replace({ name: 'home' })
        }
      }
    },
    async login({ dispatch }, userData) {
      let response = await axios.post('sign_in', {
        session: { ...userData },
      })
      dispatch('persist', response)
    },
    async logOut({ commit }) {
      await axios.delete('sign_out')
      commit('authUser', null)
      commit('setActiveDevice', null)
      commit('setActiveAccount', null)
      window.Intercom('shutdown')
      router.replace({ name: 'login' })
    },
    updateActiveDevice({ commit }, data) {
      commit('setActiveDevice', data)
    },
    updateActiveAccount({ commit }, data) {
      commit('setActiveAccount', data)
    },
  },
}
