export default {
  'sidebar': {
    'dashboard': 'Dashboard',
    'manage_users': 'Administrar Usuarios',
    'users': 'Usuarios',
    'accounts': 'Clientes',
    'devices': 'Dispositivos',
    'policy': 'Configuración',
    'reports': 'Informes',
    'administration': 'Administración',
    'resellers': 'Resellers',
    'users_reseller': 'Usuarios Reseller'
  },
  'general': {
    'api_key': 'API KEY',
    'submit': 'Enviar',
    'add': 'Añadir',
    'search': 'Buscar',
    'profile': 'Perfil',
    'logout': 'Cerrar sesión',
    'user_profile': 'Perfil de usuario',
    'password': 'Contraseña',
    'change_password': 'Cambiar contraseña',
    'new_password': 'Nueva contraseña',
    'repeat_password': 'Repite contraseña',
    'mach_password': "Contraseña no coincide",
    'min_length_password': "La contraseña debe tener más de 8 caracteres",
    'uppercase_password': "La contraseña debe contener mayúscula",
    'lowercase_password': "La contraseña debe contener minúscula",
    'need_number_password': "La contraseña debe contener un número",
    'special_ch_password': "La contraseña debe contener un carácter especial #?!@$%^&*-",
    'language': 'Idioma',
    'of': 'de',
    'select': 'Seleccione',
    'action': 'Acción',
    'no_records_found': 'No se encontraron registros',
    'no_search_results_found': 'No se encontraron resultados de búsqueda',
    'all_devices': 'Todos los dispositivos',
    'all_accounts': 'Todos los clientes',
    'no_edit_device': 'No puedes editar la configuración del dispositivo porque pertence al grupo \'@1\'. Por favor, edite el grupo en su lugar.',
    'required': 'Requerido',
    'read_only': 'Solo lectura',
    'subscription': 'Suscripción',
    'cancel': 'Cancelar',
    'back': 'Atrás',
    'update': 'Actualizar',
    'dyndns_configuration': 'Configuración DynDNS',
    'username': 'Nombre de usuario',
    'server': 'Servidor',
    'first_name': 'Primer Nombre',
    'last_name': 'Apellido',
    'email': 'Correo Electrónico',
    'customer_data': 'Datos cliente',
    'user_data': 'Datos usuario',
    'demo_data': 'Datos demo',
    'new_demo_account': 'Nueva cuenta demo',
    'name': 'Nombre',
    'message': 'Mensaje',
    'request_sent': 'Petición enviada',
    'send': 'Enviar',
    'created_at': 'Creado el',
  },
  'locked': {
    'access_denied': 'Acceso denegado',
    'sorry': 'Lo siento,',
    'param': '{param}',
    'blocked_by_network_admin': 'ha sido bloqueado por el administrador de la red',
    'locked_page': 'Página bloqueada',
    'protected_by': 'Protegido por {param}',
    'unlock_request': 'Solicitud de desbloqueo',
    'unlock_website_request': 'Solicitud de desbloqueo de sitio web'
  },
  'errors': {
    'invalid_param': 'Ingrese un {param} válido',
    'min_length': 'Longitud mínima de {n} caracteres',
    'max_length': 'Longitud máxima de {n} caracteres',
    'must_match': 'Debe coincidir',
    'too_many_requests': 'Demasiadas solicitudes',
  },
  'language': {
    'german': 'Deutsch',
    'english': 'English',
    'spanish': 'Español',
  },
  'dashboard': {
    'no_productivity_by_categories': 'No productividad por categorías',
    'productivity_by_categories': 'Productividad por categorías',
    'productivity': 'Productividad',
    'noProductivity': 'No productividad',
    'productivity_time': 'Productividad por tiempo',
    'current_productivity': 'Actual',
    'max_productivity': 'Máxima',
    'average_productivity': 'Media',
    'filter': 'Filtrar',
    'filter_by_time': 'Filtrar',
    'filter_by_device': 'Dispositivo',
    'today': 'Hoy',
    'yesterday': 'Ayer',
    'past_7_days': 'Últimos 7 días',
    'past_30_days': 'Últimos 30 días',
    'more': 'Más',
    'traffic_over_time': 'Tráfico a lo largo del tiempo',
    'allowed_traffic': 'Tráfico permitido',
    'blocked_traffic': 'Tráfico bloqueado',
    'allowed_traffic_time': 'Tráfico permitido por tiempo',
    'blocked_traffic_time': 'Tráfico bloqueado por tiempo',
    'allowed_traffic_regions': 'Tráfico permitido por regiones',
    'blocked_traffic_regions': 'Tráfico bloqueado por regiones',
    'time': 'tiempo',
    'location': 'Ubicación',
    'traffic_counts': 'Recuentos de tráfico',
    'categories': 'Categorías',
    'geolocations': 'Geolocalizaciones',
    'count': 'cantidad',
    'allowed_categories': 'Categorías permitidas',
    'blocked_categories': 'Categorías bloqueadas',
    'allowed_geolocations': 'Geolocalizaciones permitidas',
    'blocked_geolocations': 'Geolocalizaciones bloqueadas',
    'allowed': 'Permitida',
    'blocked': 'Bloqueada',
    'loading_chart': 'Cargando diagrama',
    'malwares_blocked_by_time': 'Malware bloqueado por tiempo',
    'malware_blocked_by_categories': 'Malware bloqueado por categorías',
    'malware_blocked_by_regions': 'Malware bloqueado por regiones',
    'select_device_message': 'Por favor seleccione un dispositivo o grupo',
    'producivity_traffic': 'Tráfico productivo',
    'no_producivity_traffic': 'Tráfico no productivo'
  },
  'reports': {
    'day': 'Día',
    'category_name': 'Nombre de la categoría',
    'region_name': 'Nombre de la región',
    'domain_name': 'Nombre de dominio',
    'donwload_data': 'Descargar datos',
    'stats_by_time_allowed': 'Permitidas por fecha',
    'stats_by_time_denied': 'Bloqueadas por fecha',
    'stats_by_category_allowed': 'Permitidas por categoría',
    'stats_by_category_denied': 'Bloqueadas por categoría',
    'stats_by_region_allowed': 'Permitidas por región',
    'stats_by_region_denied': 'Bloqueadas por región',
    'stats_by_domain_allowed': 'Permitidas por dominio',
    'stats_by_domain_denied': 'Bloqueadas por dominio',
    'stats_by_malware_host': 'Hosts infectados con malware',
    'stats_by_malware_threat': 'Amenazas bloqueadas',
    'stats_by_productivity_categories': 'Productivas por categoría',
    'stats_by_no_productivity_categories': 'No productivas por categoría',
    'by_date': 'Por fecha',
    'by_time': 'Por tiempo',
    'categories': 'Categorías',
    'regions': 'Regiones',
    'domains': 'Dominios',
    'malware': 'Malicioso',
    'productivity': 'Productividad',
    'malware_host': 'Host infectado bloqueado',
    'malware_threat': 'Amenazas bloqueadas',
    'productivity_categories': 'Categorías productivas',
    'no_productivity_categories': 'Categorías no productivas',
    'from': 'Desde',
    'to': 'A',
    'view': 'Ver',
    'download': 'Descargar',
    'email': 'Email',
    'no_table_data': 'No hay datos para la tabla',
    'traffic_logs': 'Logs de Tráfico',
    'requests': 'Solicitudes'
  },
  'devices': {
    'devices_group': 'Grupos de dispositivos',
    'create_group': 'Crear nuevo grupo',
    'create': 'Crear',
    'name_group': 'Nombre de grupo',
    'group_devices': 'Número de dispositivos',
    'remove_group': 'Eliminar grupo',
    'remove': 'Eliminar',
    'no_groups_found': 'No se encontraron grupos',
    'edit_group': 'Editar grupo',
    'update_group_success': 'Grupo actualizado correctamente',
    'remove_group_success': 'Grupo eliminado correctamente',
    'search_group_placeholder': 'Buscar por nombre de grupo',
    'group_name_required': 'Proporcione el nombre del grupo',
    'group_devices_list_required': 'La lista de dispositivos no puede estar vacía',
    'template': 'Plantilla',
    'name_device': 'Nombre dispositivo',
    'remove_device': 'Eliminar dispositivo',
    'qr_scan': 'Escanear QR',
    'qr_upload': 'Subir QR',
    'add_new_device': 'Agrega un nuevo dispositivo',
    'i_agree': 'Estoy de acuerdo con la',
    'not_license_available': 'No hay licencia disponible',
    'terms_and_conditions': 'términos y Condiciones',
    'enter_device_code': 'Introduce el código de dispositivo',
    'scan_qr_code': 'Escanear código QR',
    'device_list': 'Lista de dispositivos',
    'drag_drop_qr': 'imagen de arrastre y caída de QR',
    'enter_details': 'Introduzca los detalles del dispositivo',
    'device_code_required': 'Por favor, proporcione código del dispositivo',
    'device_code_present': 'El código de dispositivo ya existe',
    'device_name_required': 'Por favor, proporcione el nombre del dispositivo',
    'device_name_present': 'El nombre del dispositivo ya existe',
    'device_ip_required': 'Por favor, proporcione el IP del dispositivo',
    'device_ip_not_valid': 'Proporcione una dirección IP válida',
    'device_hostname_not_valid': 'Hostname no válido',
    'device_ip_present': 'El IP del dispositivo ya existe',
    'device_hostname_present': 'Hostname ya registrado',
    'search_placeholder': 'Buscar por el nombre de dispositivo, código o nombre de usuario',
    'device_name': 'Nombre del dispositivo',
    'device_code': 'Código del dispositivo',
    'no_devices_found': 'No se encontraron dispositivos',
    'user_name': 'Nombre de usuario',
    'deactivate': 'Desactivar',
    'deactivation_confirmed': 'Desactivación confirmada',
    'deactivation_requested': 'Desactivación solicitada',
    'confirm_title': 'Desactivar dispositivo',
    'confirm_content': '¿Está seguro de que desea desactivar el dispositivo <b>{device_name}</b>?',
    'message_title': 'Solicitud confirmada',
    'message_content': 'Recibimos su solicitud para el dispositivo <b>{device_name}</b>. Nuestro representante lo llamará en 48 horas para la desactivación.',
    'deactivate_success': 'Desactivado correctamente',
    'cancel': 'Cancelar',
    'okay': 'Aceptar',
    'yes': 'Si',
    'add_new_device_success': 'Nuevo dispositivo agregado correctamente',
    'ip_address': 'IP',
    'ip_address_type': 'Tipo de IP',
    'ip_static': 'IP Fija',
    'ip_dynamic': 'IP Dinámica',
    'external_ip_dynamic': 'IP Dinámica externa',
    'internal_ip_dynamic': 'IP Dinámica interna',
    'ip_address_hostname': 'IP / nombre de host',
    'dyndns': 'DynDNS',
    'hostname': 'Hostname',
    'edit_device': 'Editar dispositivo',
    'update': 'Actualizar',
    'update_device_success': 'Actualización exitosa del dispositivo',
    'select_device_state': 'Seleccionar estado de Dispositivo',
    'active': 'Activo',
    'all_devices': 'Todos los dispositivos',
    'deactivated': 'Desactivado',
    'remove_device_success': 'Dispositivo eliminado con éxito',
    'group_repeated': 'Este nombre ya está registrado',
    'status': 'Estado',
    'last_connection': 'Última conexión',
    'delete_device': 'Eliminar dispositivo',
    'disconnected': 'Sin conexión',
    'disconnected_devices': 'Dispositivos desconectados',
    'connected_devices': 'Dispositivos conectados',
    'hostname_public_to_internet': 'Publicar hostname en internet'
  },
  'policy': {
    'cancel': 'Cancelar',
    'edit_policy': 'Editar política',
    'name': 'Nombre',
    'all_policies': 'Todas las políticas',
    'policy_name_required': 'Proporcione el nombre de la política',
    'policy_name_present': 'El nombre de la política ya existe',
    'update': 'Actualizar',
    'update_policy_success': 'Actualización exitosa de la política',
    'create_policy': 'Crear política',
    'select_policy': 'Selecciona una política',
    'productivity': 'Productividad',
    'geo_blocking': 'GeoBlocking',
    'geo_blocking_rules_list': 'Regiones',
    'allow': 'Permitir',
    'block': 'Bloquear',
    'protection': 'Proteccion',
    'custom_config': 'Configuración personalizada',
    'categories_list': 'Categorías',
    'go_to_easy_config': 'Ir a la configuración fácil',
    'system_blacklist': 'Sistema de lista negra',
    'choose_policy': 'ELIJA UNA DE NUESTROS NIVELES DE SEGURIDAD PREDEFINIDOS',
    'base': 'Base',
    'medium': 'Medio',
    'high': 'Alto',
    'custom': 'Personalizado',
    'add_placeholder': 'youtube.com',
    'search_label': 'Buscar',
    'search_placeholder': 'Escribe para buscar',
    'personal_blacklist': 'Dominios bloqueados',
    'personal_whitelist': 'Dominios permitidos',
    'search_engine_protection': 'Motores de búsqueda',
    'search_engines': 'Protección de Búsqueda para Google® y Bing®',
    'search_engine_tooltip': 'Activa la búsqueda segura para Google/Bing para limitar los resultados de búsqueda (páginas web, imágenes y contenido). Únicamente se mostrarán los resultados seguros y apropiados. Pornografía y otros contenidos no deseados no serán incluidos entre los resultados de la búsqueda.resultados de la búsqueda',
    'blocked_categories': 'Categorías bloqueadas',
    'successfully_changed_config': 'Se ha cambiado correctamente la configuración para',
    'empty_domain': 'Proporcione un dominio',
    'domain_already_added': 'El dominio ingresado ya está agregado',
    'invalid_domain': 'Por favor, proporcione un dominio válido',
    'successfully_added_to_whitelist': '\'{siteName}\' fue agregado correctamente a la lista blanca',
    'successfully_removed_from_whitelist': '\'{siteName}\' se ha eliminado correctamente de la lista blanca',
    'successfully_added_to_blacklist': '\'{siteName}\' fue agregado correctamente a la lista negra',
    'successfully_removed_from_blacklist': '\'{siteName}\' se ha eliminado correctamente de la lista negra',
    'successfully_added_to_productivitylist': '\'{siteName}\' fue agregado correctamente a Categoría específica de mi negocio',
    'successfully_removed_from_productivitylist': '\'{siteName}\' se ha eliminado correctamente de Categoría específica de mi negocio',
    'select_device_message': 'Seleccione un dispositivo para continuar',
    'enabled_search_engine_protection': 'Protección de motores de búsqueda activada',
    'disabled_search_engine_protection': 'Protección de motores de búsqueda desactivada',
    'selected_device_is_deactivated': 'El dispositivo seleccionado se desactiva. Por favor seleccione otro dispositivo',
    'legend': 'Leyenda'
  },
  'categories': {
    'category_business': 'Categoría específica de mi negocio',
    'ads_spam_web_stats': 'Anuncios, Span y Web Stats',
    'advertising': 'Publicidad',
    'domain_for_sale': 'Dominio en venta',
    'e_commerce': 'Comercio electrónico',
    'job_search': 'Búsqueda de trabajo',
    'web_stats': 'Estadísticas web',
    'audio_video': 'Audio Video',
    'amazon_prime_video': 'Amazon Prime Video',
    'netflix': 'Netflix',
    'others_audio_video': 'Otras Audio y Video',
    'spotify': 'Spotify',
    'youtube': 'YouTube',
    'dangerous': 'Peligroso',
    'coronavirus_frauds': 'Fraudes de coronavirus',
    'gambling': 'Apuestas',
    'p2p_and_filesharing': 'P2P y intercambio de archivos',
    'proxy': 'Proxy',
    'tools_for_hacking': 'Herramientas para piratear',
    'blog': 'Blog',
    'books': 'Libros',
    'fashion': 'Moda',
    'free_time': 'Tiempo libre',
    'music': 'Música',
    'pets': 'Mascotas',
    'photography': 'Fotografía',
    'radio': 'Radio',
    'religion': 'Religión',
    'shopping': 'Compras',
    'shows_vips': 'Shows y VIPs',
    'sport': 'Deporte',
    'travel': 'Viaje',
    'generic_safe_websites': 'Sitios web genéricos seguros',
    'malware_threats': 'Amenazas de malware',
    'backdoor': 'Puerta trasera',
    'botnet': 'Botnet',
    'dangerous_threats': 'Amenazas peligrosas',
    'malicious_activity': 'Actividad maliciosa',
    'malicious_dns_activity': 'Actividad de DNS maliciosa',
    'malicious_web_sites': 'Páginas web maliciosas',
    'malware_community': 'Comunidad de malware',
    'phishing': 'Suplantación de identidad',
    'phishing_community': 'Comunidad de phishing',
    'ransomware_and_cryptowall': 'Ransomware y Cryptowall',
    'suspicious_web_activity': 'Actividad web sospechosa',
    'trojan': 'Troyano',
    'worm': 'Gusano',
    'news': 'Noticias',
    'search_engines': 'Motores de búsqueda',
    'social_network': 'Red social',
    'facebook': 'Facebook',
    'instagram': 'Instagram',
    'other_social_networks': 'Otras redes sociales',
    'tiktok': 'TikTok',
    'twitter': 'Twitter',
    'whatsapp_vocals_calls_only': 'WhatsApp (solo voces y llamadas)',
    'technology': 'Tecnología',
    'cdn': 'CDN',
    'file_hosting': 'Alojamiento de archivos',
    'iana_domains_tld_and_arpa': 'Dominios IANA, TLD y ARPA',
    'instant_messaging': 'Mensajería instantánea',
    'online_services': 'Servicios en línea',
    'sip_provider': 'Proveedor SIP',
    'software_tech': 'Software y tecnología',
    'updates': 'Actualizaciones',
    'web_mail': 'Correo web',
    'unwanted_websites': 'Sitios Web No Deseados',
    'adult': 'Adulto',
    'chat': 'Charla',
    'colombian_government_blacklist': 'Lista negra del gobierno colombiano',
    'dating': 'Citas',
    'desktop_wallpapers': 'Fondos de escritorio',
    'dns_proxy': 'Proxy DNS',
    'drugs': 'Drogas',
    'games': 'Juegos',
    'online_auctions': 'Subastas en línea',
    'porn': 'Pornografía',
    'sexuality': 'Sexualidad',
    'weapons': 'Armas',
    'work': 'Trabajo',
    'associations': 'Asociaciones',
    'companies': 'Empresas',
    'education_training': 'Educación y formación',
    'financial': 'Financiera',
    'legal': 'Legal',
    'medical': 'Médica',
    'pa_government': 'Autoridad Palestina y Gobierno',
    'others': 'Otros',
    'uncategorized': 'Sin categorizar',
    'ads': 'Anuncios',
    'parked_domains': 'Dominios Aparcados',
    'web_advertisements': 'Anuncios Web',
    'hacking': 'Hacking',
    'open_http_proxies': 'HTTP Proxies Libres',
    'pay_to_surf': 'Pagar para navegar',
    'peer_to_peer': 'Peer to Peer',
    'local_information': 'Información Local',
    'abortion': 'Aborto',
    'military': 'Militar',
    'computer_and_internet_info': 'Noticias sobre Ordenadores e Internet',
    'entertainment_and_arts': 'Entretenimiento y Arte',
    'fashion_and_beauty': 'Fashion y Belleza',
    'food_and_dining': 'Comida y Bebida',
    'home_and_garden': 'Casa y Jardín',
    'hunting_and_fishing': 'Caza y Pesca',
    'internet_portals': 'Portales de Internet',
    'kids': 'Contenido Infantil',
    'motor_vehicles': 'Automoción',
    'online_greeting_cards': 'Tarjetas Regalo',
    'personal_sites_and_blogs': 'Blogs y Sitios Personales',
    'philosophy_and_political_advocacy': 'Filosofía y Política',
    'real_estate': 'Inmuebles',
    'recreation_and_hobbies': 'Hobbies',
    'society': 'Sociedad',
    'sports': 'Deportes',
    'bot_nets': 'Bot Nets',
    'confirmed_spam_sources': 'Fuentes SPAM Confirmadas',
    'keyloggers_and_monitoring': 'Keyloggers y Monitorización',
    'malware_sites': 'Sitios Malware',
    'phishing_and_other_frauds': 'Phishing y Otros Fraudes',
    'proxy_avoidance_and_anonymizers': 'Proxies Ocultos and Anonimizadores',
    'spam_urls': 'SPAM URLs',
    'spyware_and_adware': 'Spyware y Adware',
    'unconfirmed_spam_sources': 'Fuentes SPAM Sin Confirmar',
    'media': 'Multimedia',
    'news_and_media': 'Noticias and Multimedia',
    'social_networking': 'Redes Sociales',
    'streaming_media': 'Contenido en Streaming',
    'image_and_video_search': 'Buscadores de Imagen y Video',
    'computer_and_internet_security': 'Protección Ordenadores e Internet',
    'content_delivery_networks': 'Red de Distribución de Contenidos',
    'dynamically_generated_content': 'Sitios Generados Dinamicamente',
    'individual_stock_advice_and_tools': 'Herramientas para la Gestión de Stock',
    'internet_communications': 'Comunicaciones en Internet',
    'personal_storage': 'Almacenamiento Personal',
    'shareware_and_freeware': 'Shareware y Freeware',
    'translation': 'Traducción',
    'web_hosting': 'Web Hosting',
    'web_based_email': 'Web Email',
    'abused_drugs': 'Drogas',
    'adult_and_pornography': 'Contenido Adulto y Pornografía',
    'alcohol_and_tobacco': 'Alcohol y Tacabo',
    'auctions': 'Subastas',
    'cheating': 'Engaños',
    'cult_and_occult': 'Culto',
    'dead_sites': 'Sitios Perdidos',
    'gross': 'Contenido Grosero',
    'hate_and_racism': 'Odio y Racismo',
    'illegal': 'Contenido Ilegal',
    'marijuana': 'Marihuana',
    'Military': 'Contenido Militar',
    'nudity': 'Desnudos',
    'private_ip_addresses': 'Direcciones IP Privadas',
    'sex_education': 'Educación Sexual',
    'swimsuits_and_intimate_apparel': 'Ropa Intima',
    'violence': 'Violencia',
    'business_and_economy': 'Negocios y Economía',
    'educational_institutions': 'Instituciones Educativas',
    'financial_services': 'Servicios Financieros',
    'government': 'Gobierno',
    'health_and_medicine': 'Salud y Medicina',
    'reference_and_research': 'Investigación y Bibliografía',
    'training_and_tools': 'Herramientas Educativas',
    'self_harm': 'Autolesión',
    'dns_over_https': 'DNS sobre HTTPS',
    'low_thc_cannabis_products': 'Productos de cannabis bajos en THC',
    'generative_ai': 'IA generativa'
  },
  'regions': {
    "afghanistan": "Afganistán",
    "africa": "África",
    "aland_islands": "Aland Islands",
    "albania": "Albania",
    "algeria": "Algeria",
    "american_samoa": "American Samoa",
    "andorra": "Andorra",
    "angola": "Angola",
    "anguilla": "Anguilla",
    "anonymous_proxy": "Proxy Anónimo",
    "antarctica_(the_territory_south_of_60_deg_s)": "Antarctica (the territory South of 60 deg S)",
    "antarctica": "Antarctica",
    "antigua_and_barbuda": "Antigua and Barbuda",
    "argentina": "Argentina",
    "armenia": "Armenia",
    "aruba": "Aruba",
    "asia_pacific_region": "Asia pacific region",
    "asia": "Asia",
    "australia": "Australia",
    "austria": "Austria",
    "azerbaijan": "Azerbaijan",
    "bahamas": "Bahamas",
    "bahrain": "Bahrain",
    "baltics": "Baltics",
    "bangladesh": "Bangladesh",
    "barbados": "Barbados",
    "belarus": "Belarus",
    "belgium": "Belgium",
    "belize": "Belize",
    "benin": "Benin",
    "bermuda": "Bermuda",
    "bhutan": "Bhutan",
    "bolivia": "Bolivia",
    "bonaire_saint_eustatius_and_saba": "Bonaire Saint Eustatius and Saba",
    "bonaire": "Bonaire",
    "bosnia_and_herzegovina": "Bosnia and Herzegovina",
    "botswana": "Botswana",
    "bouvet_island_(bouvetoya)": "Bouvet Island (Bouvetoya)",
    "brazil": "Brazil",
    "british_indian_ocean_territory_(chagos_archipelago)": "British Indian Ocean Territory (Chagos Archipelago)",
    "british_indian_ocean_territory": "British Indian ocean territory",
    "british_virgin_islands": "British Virgin Islands",
    "brunei_darussalam": "Brunei Darussalam",
    "bulgaria": "Bulgaria",
    "burkina_faso": "Burkina Faso",
    "burundi": "Burundi",
    "cambodia": "Cambodia",
    "cameroon": "Cameroon",
    "canada": "Canada",
    "cape_verde": "Cape Verde",
    "cayman_islands": "Cayman Islands",
    "central_african_republic": "Central African Republic",
    "chad": "Chad",
    "chile": "Chile",
    "china": "China",
    "christmas_island": "Christmas Island",
    "cocos_(keeling)_islands": "Cocos (Keeling) Islands",
    "colombia": "Colombia",
    "comoros": "Comoros",
    "congo_the_democratic_republic_of_the": "Congo, The Democratic Republic of the",
    "congo": "Congo",
    "cook_islands": "Cook Islands",
    "costa_rica": "Costa Rica",
    "cote_d_ivoire": "Cote d'Ivoire",
    "croatia": "Croatia",
    "cuba": "Cuba",
    "curacao": "Curacao",
    "cyprus": "Cyprus",
    "czech_republic": "Czech Republic",
    "denmark": "Denmark",
    "disputed_territory": "Disputed Territory",
    "djibouti": "Djibouti",
    "dominica": "Dominica",
    "dominican_republic": "Dominican Republic",
    "eastern_europe": "Eastern Europe",
    "ecuador": "Ecuador",
    "egypt": "Egypt",
    "el_salvador": "El Salvador",
    "equatorial_guinea": "Equatorial Guinea",
    "eritrea": "Eritrea",
    "estonia": "Estonia",
    "ethiopia": "Ethiopia",
    "europe": "Europe",
    "falkland_islands_(malvinas)": "Falkland Islands (Malvinas)",
    "faroe_islands": "Faroae Islands",
    "fiji": "Fiji",
    "finland": "Finland",
    "france": "France",
    "french_guiana": "French Guiana",
    "french_polynesia": "French Polynesia",
    "french_southern_territories": "French Southern Territories",
    "gabon": "Gabon",
    "gambia": "Gambia",
    "georgia": "Georgia",
    "germany": "Germany",
    "ghana": "Ghana",
    "gibraltar": "Gibraltar",
    "greece": "Greece",
    "greenland": "Greenland",
    "grenada": "Grenada",
    "guadeloupe": "Guadeloupe",
    "guam": "Guam",
    "guatemala": "Guatemala",
    "guernsey": "Guernsey",
    "guinea_bissau": "Guinea Bissau",
    "guinea": "Guinea",
    "guyana": "Guyana",
    "haiti": "Haiti",
    "heard_island_and_mcdonald_islands": "Heard Island and McDonald Islands",
    "holy_see_(vatican_city_state)": "Holy See (Vatican City State)",
    "honduras": "Honduras",
    "hong_kong": "Hong Kong",
    "hungary": "Hungary",
    "iceland": "Iceland",
    "india": "India",
    "indonesia": "Indonesia",
    "ip_not_defined": "Ip not defined",
    "iran_islamic_republic_of": "Iran, Islamic Republic of",
    "iran": "Iran",
    "iraq_saudi_arabia_neutral_zone": "Iraq-Saudi Arabia Neutral Zone",
    "iraq": "Iraq",
    "ireland": "Ireland",
    "isle_of_man": "Isle of Man",
    "israel": "Israel",
    "italy": "Italy",
    "jamaica": "Jamaica",
    "japan": "Japan",
    "jersey": "Jersey",
    "jordan": "Jordan",
    "kazakhstan": "Kazakhstan",
    "kenya": "Kenya",
    "kiribati": "Kiribati",
    "north_korea": "North Korea",
    "south_korea": "South Korea",
    "korea": "Korea",
    "kuwait": "Kuwait",
    "kyrgyz_republic": "Kyrgyz Republic",
    "kyrgyzstan": "Kyrgyzstan",
    "lao_people's_democratic_republic": "Lao People\'s Democratic Republic",
    "latin_america_and_caribbean": "Latin America and Caribbean",
    "latvia": "Latvia",
    "lebanon": "Lebanon",
    "lesotho": "Lesotho",
    "liberia": "Liberia",
    "libya": "Libya",
    "libyan_arab_jamahiriya": "Libyan Arab Jamahiriya",
    "liechtenstein": "Liechtenstein",
    "lithuania": "Lithuania",
    "luxembourg": "Luxembourg",
    "macao": "Macao",
    "macau": "macau",
    "macedonia": "Macedonia",
    "madagascar": "Madagascar",
    "malawi": "Malawi",
    "malaysia": "Malaysia",
    "maldives": "Maldives",
    "mali": "Mali",
    "malta": "Malta",
    "marshall_islands": "Marshall Islands",
    "martinique": "Martinique",
    "mauritania": "Mauritania",
    "mauritius": "Mauritius",
    "mayotte": "Mayotte",
    "mexico": "Mexico",
    "micronesia_federated_states_of": "Micronesia Federated States of",
    "micronesia": "Micronesia",
    "middle_east": "Middle East",
    "moldova_republic_of": "Moldova Republic of",
    "moldova": "Moldova",
    "monaco": "Monaco",
    "mongolia": "Mongolia",
    "montenegro": "Montenegro",
    "montserrat": "Montserrat",
    "morocco": "Morocco",
    "mozambique": "Mozambique",
    "myanmar": "Myanmar",
    "namibia": "Namibia",
    "nauru": "Nauru",
    "nepal": "Nepal",
    "netherlands_antilles": "Netherlands Antilles",
    "netherlands": "Netherlands",
    "new_caledonia": "New Caledonia",
    "new_zealand": "New Zealand",
    "nicaragua": "Nicaragua",
    "niger": "Niger",
    "nigeria": "Nigeria",
    "niue": "Niue",
    "norfolk_island": "Norfolk Island",
    "north_africa": "North Africa",
    "north_america": "Norteamérica",
    "northern_mariana_islands": "Northern Mariana Islands",
    "norway": "Norway",
    "oceania": "Oceania",
    "oman": "Oman",
    "pakistan": "Pakistan",
    "palau": "Palau",
    "palestinian_territory": "Palestinian Territory",
    "panama": "Panama",
    "papua_new_guinea": "Papua New Guinea",
    "paraguay": "Paraguay",
    "peru": "Peru",
    "philippines": "Philippines",
    "pitcairn_islands": "Pitcairn Islands",
    "poland": "Poland",
    "portugal": "Portugal",
    "puerto_rico": "Puerto Rico",
    "qatar": "Qatar",
    "reunion": "Reunion",
    "romania": "Romania",
    "russia_and_c_w_of_ind_states": "Russia and C.W. of Ind. States",
    "russian_federation": "Russian Federation",
    "rwanda": "Rwanda",
    "saint_barthelemy": "Saint Barthelemy",
    "saint_helena": "Saint Helena",
    "saint_kitts_and_nevis": "Saint Kitts and Nevis",
    "saint_lucia": "Saint Lucia",
    "saint_martin": "Saint Martin",
    "saint_pierre_and_miquelon": "Saint Pierre and Miquelon",
    "saint_vincent_and_the_grenadines": "Saint Vincent and the Grenadines",
    "samoa": "Samoa",
    "san_marino": "San Marino",
    "sao_tome_and_principe": "Sao Tome and Principe",
    "satellite_connections": "Satellite connections",
    "satellite_provider": "Satellite Provider",
    "saudi_arabia": "Saudi Arabia",
    "senegal": "Senegal",
    "serbia": "Serbia",
    "seychelles": "Seychelles",
    "sierra_leone": "Sierra Leone",
    "singapore": "Singapore",
    "sint_maarten_dutch_part": "Sint Maarten Dutch Part",
    "sint_maarten_(netherlands)": "Sint Maarten (Netherlands)",
    "slovakia_(slovak_republic)": "Slovakia (Slovak Republic)",
    "slovakia": "Slovakia",
    "slovenia": "Slovenia",
    "solomon_islands": "Solomon Islands",
    "somalia": "Somalia",
    "south_africa": "South Africa",
    "south_america": "Sudamérica",
    "south_georgia_and_the_south_sandwich_islands": "South Georgia and The South Sandwich Islands",
    "south_sudan": "South Sudan",
    "spain": "España",
    "spratly_islands": "Spratly Islands",
    "sri_lanka": "Sri Lanka",
    "sub_saharan_africa": "Sub-Saharan Africa",
    "sudan": "Sudan",
    "suriname": "Suriname",
    "svalbard_and_jan_mayen": "Svalbard and Jan Mayen",
    "svalbard_jan_mayen_islands": "Svalbard & Jan Mayen Islands",
    "swaziland": "Swaziland",
    "sweden": "Sweden",
    "switzerland": "Switzerland",
    "syrian_arab_republic": "Syrian Arab Republic",
    "taiwan": "Taiwan",
    "tajikistan": "Tajikistan",
    "tanzania": "Tanzania",
    "thailand": "Thailand",
    "timor_leste": "Timor-Leste",
    "togo": "Togo",
    "tokelau": "Tokelau",
    "tonga": "Tonga",
    "trinidad_and_tobago": "Trinidad and Tobago",
    "tunisia": "Tunisia",
    "turkey": "Turkey",
    "turkmenistan": "Turkmenistan",
    "turks_and_caicos_islands": "Turks and Caicos Islands",
    "tuvalu": "Tuvalu",
    "uganda": "Uganda",
    "ukraine": "Ukraine",
    "united_arab_emirates": "Emiratos Árabes Unidos",
    "united_kingdom_of_great_britain_&_northern_ireland": "United Kingdom of Great Britain & Northern Ireland",
    "united_kingdom": "United Kingdom",
    "united_nations_neutral_zone": "United Nations Neutral Zone",
    "united_states_minor_outlying_islands": "United States Minor Outlying Islands",
    "united_states_of_america": "Estados Unidos",
    "united_states_virgin_islands": "United States Virgin Islands",
    "united_states": "United States",
    "uruguay": "Uruguay",
    "uzbekistan": "Uzbekistan",
    "vanuatu": "Vanuatu",
    "venezuela": "Venezuela",
    "vietnam": "Vietnam",
    "virgin_islands_british": "Virgin Islands British",
    "virgin_islands_u_s": "Virgin Islands, U.S.",
    "wallis_and_futuna": "Wallis and Futuna",
    "western_sahara": "Western Sahara",
    "yemen": "Yemen",
    "zambia": "Zambia",
    "zimbabwe": "Zimbabwe",
  },
  'profile': {
    'first_name': 'Primer Nombre',
    'last_name': 'Apellido',
    'email': 'Correo Electrónico',
    'no_of_devices': 'No de dispositivos agregados',
    'devices': 'Dispositivos',
    'support': 'Soporte',
    'details_support': 'Detalles de contacto',
    'reseller_token': 'Token del Reseller',
    'manual': 'Manual',
    'current_password': 'Contraseña actual',
    'manual_url': 'https://storage.googleapis.com/bssdownloads/Dm953-I-BeSafeSmartUserManual-Es.pdf'
  },
  'role': {
    'account_managers': 'Administradores',
    'reseller_manager': 'Reseller Administrador',
    'reseller_managers': 'Resellers Administradores',
    'end_users': 'Usuarios',
    'account_manager': 'Administrador',
    'end_user': 'Usuario',
    'role': 'Rol',
    'reseller_user': 'Usuario Reseller',
    'resellers': 'Resellers'
  },
  'users': {
    'create_user': 'Crear usuario',
    'no_users_found': 'No se encontraron usuarios',
    'search_placeholder': 'Buscar por usuario o correo electrónico',
    'delete': 'Eliminar usuario',
    'confirm_delete': 'Esta acción es permanente. ¿Quiere continuar?'
  },
  'accounts': {
    'accounts': 'Clientes',
    'create_account': 'Crear nuevo cliente',
    'name_account': 'Nombre del cliente',
    'account_name_required': 'Proporcione nombre del cliente',
    'create_end_user': 'Crear nuevo usuario',
    'total_licenses': 'Total licencias',
    'new_licenses': 'Nuevas licencias',
    'new_duration': 'Nueva duración',
    'create_account_success': 'Cliente creado correctamente',
    'create_account_manager': 'Crear administrador',
    'create_end_user': 'Crear usuario',
    'available_licenses': 'Licencias disponibles',
    'select_account': 'Seleccione cliente',
    'license_type': 'Tipo de licencia',
    'edit_licenses': 'Añadir licencias',
    'edit': 'Editar Cliente',
    'search_placeholder': 'Buscar por el nombre del cliente'
  },
  'resellers': {
    'delete_success': 'Reseller eliminado correctamente',
    'delete_error': 'Error al eliminar un reseller'
  },
  'licenses': {
    'licenses': 'Licencias',
    'license': 'Licencia',
    'expiration_license': 'Caducidad Licencias',
    'duration': 'Duracion Licencias (Meses)',
    'type': 'Tipo Licencias',
  },
  'productivity': {
    'no_category_found': 'No se encontraron categorías',
    'search_placeholder': 'Buscar por categoría'
  },
  'administration': {
    'create_demo_organization': 'Crear cliente demo',
    'create_reseller': 'Crear reseller',
    'name': 'Nombre',
    'empty_name': 'Error: Nombre vacio',
    'invalid_repeated_name': 'Error: Nombre ya registrado',
    'phone': 'Teléfono',
    'contact': 'Contacto',
    'search_by_name': 'Buscar por nombre...',
    'delete_reseller': 'Eliminar reseller',
    'create_organization': 'Crear cliente',
    'delete_account': 'Eliminar cliente',
    'successfully': 'Successfully',
    'organization': 'Cliente',
    'reseller': 'Reseller',
    'hours': 'Horario',
    'openning_hours': 'L-V 08:00-15:00',
    'besafe_points': 'Puntos besafe',
    'total_besafe_points': 'Puntos besafe totales',
    'new_besafe_points': 'Puntos besafe nuevos',
    'invalid_besafe_points': 'Error: puntos besafe no válidos',
  },
  'subscription': {
    'billing_address_not_found': 'Dirección de facturación no encontrada',
    'status': 'Estado',
    'license_used': 'Licencia utilizada',
    'licenses_available': 'Licencias disponibles',
    'price': 'Precio',
    'invoices': 'Facturas',
    'next_billing_date': 'Siguiente Fecha de facturación',
    'edit_payment_method': 'Editar método de pago',
    'cancel_subscription': 'Cancelar suscripción',
    'cancel_subscription_success': 'Suscripción cancelada correctamente',
    'reactivate_subscription': 'Reactivar subscripción',
    'reactivate_subscription_success': 'Subscripción reactivada correctamente',
    'ending_in': 'terminando en',
    'expires': 'caduca',
    'payment_method': 'Método de pago',
    'expiration_date': 'Fecha de expiración',
    'terms_and_conditions': 'Términos y condiciones',
    'accepted_on': 'aceptados el',
    'amount': 'Importe',
    'period': 'Periodo',
    'date': 'Fecha',
    'confirm_cancel': '¿Desea cancelar la subscripción?',
    'update_payment': 'Actualizar método de pago',
    'update_payment_success': 'Método de pago actualizado correctamente'
  },
  'helpers': {
    'device_name': 'Nombre del dispositivo es usado como identificador.',
    'device_code': 'Código del dispositivo es el serial number de tu dispositivo.',
    'ip_type': '<li><b>IP estática:</b> Usa esta opción si tu dispositivo tiene una IP estática (ex: 85.24.63.13).</li><li><b>IP dinámica externa:</b>usa esta opción si tu dispositivo tiene IP dinámica y ya tienes registrado tu propio servidor de dyndns (ex: device_01.yourcompany.com)</li><li><b>IP dinámica interna:</b>Usa esta opción si tu dispositivo tiene IP dinamica y no dispones de ningun servicio dyndns externo. Puedes registrar un hostname con \‘*.cloudteldat.com\’ como dominio interno.</li>',
    'switch': '<li>Switch encendido: tráfico permitido</li><li>Switch apagado: tráfico bloqueado</li>'
  }
}
