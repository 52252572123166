export default {
  'sidebar': {
    'dashboard': 'Dashboard',
    'manage_users': 'Manage Users',
    'users': 'Users',
    'accounts': 'Customers',
    'devices': 'Devices',
    'policy': 'Configuration',
    'reports': 'Reports',
    'administration': 'Administration',
    'resellers': 'Resellers',
    'users_reseller': 'Users Reseller'
  },
  'general': {
    'api_key': 'API KEY',
    'submit': 'Submit',
    'add': 'Add',
    'search': 'Search',
    'profile': 'Profile',
    'logout': 'Logout',
    'user_profile': 'User profile',
    'password': 'Password',
    'change_password': 'Change password',
    'new_password': 'New password',
    'repeat_password': 'Repeat password',
    'mach_password': "Password doesn't match",
    'min_length_password': "Password needs to be more than 8 characters",
    'uppercase_password': "Password needs to contain a uppercase",
    'lowercase_password': "Password needs to contain a lowercase",
    'need_number_password': "Password needs to contain a number",
    'special_ch_password': "Password needs to contain a special character #?!@$%^&*-",
    'required': 'Required',
    'language': 'Language',
    'of': 'of',
    'select': 'Select',
    'action': 'Action',
    'no_records_found': 'No records found',
    'no_search_results_found': 'No search results found',
    'all_devices': 'All Devices',
    'all_accounts': 'All Accounts',
    'no_edit_device': 'You cannot edit this device because is in \'@1\' group. Please, edit the group instead.',
    'required': 'Required',
    'read_only': 'Read only',
    'subscription': 'Subscription',
    'cancel': 'Cancel',
    'back': 'Back',
    'update': 'Update',
    'dyndns_configuration': 'DynDNS configuration',
    'username': 'Username',
    'server': 'Server',
    'first_name': 'First name',
    'last_name': 'Last name',
    'email': 'Email',
    'customer_data': 'Customer data',
    'user_data': 'User data',
    'demo_data': 'Demo data',
    'new_demo_account': 'New demo account',
    'name': 'Name',
    'message': 'Message',
    'request_sent': 'Request sent',
    'send': 'Send',
    'created_at': 'Created at',
  },
  'locked': {
    'access_denied': 'Access denied',
    'sorry': 'Sorry,',
    'param': '{param}',
    'blocked_by_network_admin': 'has been blocked by the network administrator',
    'locked_page': 'Locked Page',
    'protected_by': 'Protected by {param}',
    'unlock_request': 'Unlock request',
    'unlock_website_request': 'Unlock website request'
  },
  'errors': {
    'invalid_param': 'Please enter a valid {param}',
    'min_length': 'minimum length of {n} characters',
    'max_length': 'maximum length of {n} characters',
    'must_match': 'must match',
    'too_many_requests': 'Too many requests',
  },
  'language': {
    'german': 'Deutsch',
    'english': 'English',
    'spanish': 'Español',
  },
  'dashboard': {
    'no_productivity_by_categories': 'No productivity by categories',
    'productivity_by_categories': 'Productivity by categories',
    'productivity': 'Productivity',
    'noProductivity': 'No productivity',
    'productivity_time': 'Productivity by time',
    'current_productivity': 'Current',
    'max_productivity': 'Maximum',
    'average_productivity': 'Average',
    'filter': 'Filter',
    'filter_by_time': 'Filter',
    'filter_by_device': 'Device',
    'today': 'Today',
    'yesterday': 'Yesterday',
    'past_7_days': 'Past 7 Days',
    'past_30_days': 'Past 30 Days',
    'more': 'More',
    'traffic_over_time': 'Traffic over time',
    'allowed_traffic': 'Allowed Traffic',
    'blocked_traffic': 'Blocked Traffic',
    'allowed_traffic_time': 'Allowed Traffic by Time',
    'blocked_traffic_time': 'Blocked Traffic by Time',
    'allowed_traffic_regions': 'Allowed Traffic by Regions',
    'blocked_traffic_regions': 'Blocked Traffic by Regions',
    'time': 'Time',
    'location': 'Location',
    'traffic_counts': 'Traffic Counts',
    'categories': 'Categories',
    'geolocations': 'Geolocations',
    'count': 'Count',
    'allowed_categories': 'Allowed Categories',
    'blocked_categories': 'Blocked Categories',
    'allowed_geolocations': 'Allowed Geolocations',
    'blocked_geolocations': 'Blocked Geolocations',
    'allowed': 'Allowed',
    'blocked': 'Blocked',
    'loading_chart': 'Loading chart',
    'malwares_blocked_by_time': 'Malwares blocked by Time',
    'malware_blocked_by_categories': 'Malware blocked by Categories',
    'malware_blocked_by_regions': 'Malware Blocked by Regions',
    'select_device_message': 'Please select a device or group',
    'producivity_traffic': 'Productive traffic',
    'no_producivity_traffic': 'Unproductive traffic'
  },
  'reports': {
    'day': 'Day',
    'category_name': 'Category Name',
    'region_name': 'Region Name',
    'domain_name': 'Domain Name',
    'donwload_data': 'Download data',
    'stats_by_time_allowed': 'Allowed by date',
    'stats_by_time_denied': 'Blocked by date',
    'stats_by_category_allowed': 'Allowed by category',
    'stats_by_category_denied': 'Blocked by category',
    'stats_by_region_allowed': 'Allowed by region' ,
    'stats_by_region_denied': 'Blocked by region' ,
    'stats_by_domain_allowed': 'Allowed by domain' ,
    'stats_by_domain_denied': 'Blocked by domain',
    'stats_by_malware_host': 'Malware infected hosts',
    'stats_by_malware_threat': 'Blocked threats',
    'stats_by_productivity_categories': 'Productive by category',
    'stats_by_no_productivity_categories': 'Non-productive by category',
    'by_date': 'By date',
    'by_time': 'By Time',
    'categories': 'Categories',
    'regions': 'Regions',
    'domains': 'Domains',
    'malware': 'Malware',
    'productivity': 'Productivity',
    'malware_host': 'Infected host blocked',
    'malware_threat': 'Threats blocked',
    'productivity_categories': 'Productive categories',
    'no_productivity_categories': 'No productive categories',
    'from': 'From',
    'to': 'To',
    'view': 'View',
    'download': 'Download',
    'email': 'Email',
    'no_table_data': 'No data for table',
    'traffic_logs': 'Traffic logs',
    'requests': 'Requests'
  },
  'devices': {
    'devices_group': 'Device groups',
    'create_group': 'Create new group',
    'create': 'Create',
    'name_group': 'Group name',
    'group_devices': 'Number of devices',
    'remove_group': 'Remove group',
    'remove': 'Remove',
    'no_groups_found': 'No groups found',
    'edit_group': 'Edit group',
    'update_group_success': 'Updated group successfully',
    'remove_group_success': 'Removed group successfully',
    'search_group_placeholder': 'Search by Group name',
    'group_name_required': 'Please provide group name',
    'group_devices_list_required': 'The device list devices cannot be empty',
    'template': 'Template',
    'name_device': 'Name device',
    'remove_device': 'Remove device',
    'qr_scan': 'QR scan',
    'qr_upload': 'QR image upload',
    'add_new_device': 'Add new device',
    'i_agree': 'I agree to the',
    'not_license_available': 'License not available',
    'terms_and_conditions': 'terms and conditions',
    'enter_device_code': 'Enter device code',
    'scan_qr_code': 'Scan QR code',
    'device_list': 'Device List',
    'drag_drop_qr': 'Drag and drop QR image',
    'enter_details': 'Enter device details',
    'device_code_required': 'Please provide a device code',
    'device_code_present': 'Device code already exists',
    'device_name_required': 'Please provide a device name',
    'device_name_present': 'Device name already exists',
    'device_ip_required': 'Please provide an IP Address',
    'device_ip_not_valid': 'Invalid Hostname',
    'device_hostname_not_valid': 'Please enter a valid IP address',
    'device_ip_present': 'Device IP Address already exists',
    'device_hostname_present': 'Hostname already exists',
    'search_placeholder': 'Search by Device name, code or User name',
    'device_name': 'Device name',
    'device_code': 'Device code',
    'no_devices_found': 'No devices found',
    'user_name': 'User name',
    'deactivate': 'Deactivate',
    'deactivation_confirmed': 'Deactivation Confirmed',
    'deactivation_requested': 'Deactivation Requested',
    'confirm_title': 'Deactivate Device',
    'confirm_content': 'Are you sure you want to deactivate <b>{device_name}</b> device?',
    'message_title': 'Request Confirmed',
    'message_content': 'We received your request for device <b>{device_name}</b>. Our representative will call you within 48 hours for deactivation.',
    'deactivate_success': 'Successfully Deactivated',
    'cancel': 'Cancel',
    'okay': 'Okay',
    'yes': 'Yes',
    'add_new_device_success': 'Successfully added new device',
    'ip_address': 'IP',
    'ip_address_type': 'IP Type',
    'external_ip_dynamic': 'External dynamic IP',
    'internal_ip_dynamic': 'Internal dynamic IP',
    'ip_static': 'Static IP',
    'ip_dynamic': 'Dynamic IP',
    'ip_address_hostname': 'IP / Hostname',
    'dyndns': 'DynDNS',
    'hostname': 'Hostname',
    'edit_device': 'Edit Device',
    'update': 'Update',
    'update_device_success': 'Successfully updated the device',
    'select_device_state': 'Select Device state',
    'active': 'Active',
    'all_devices': 'All Devices',
    'deactivated': 'Deactivated',
    'remove_device_success': 'Device removed successfully',
    'group_repeated': 'This name is already in use',
    'status': 'Status',
    'last_connection': 'Last connection',
    'delete_device': 'Delete device',
    'disconnected': 'Disconnected',
    'disconnected_devices': 'Disconnected devices',
    'connected_devices': 'Connected devices',
    'hostname_public_to_internet': 'Publish hostname to internet'
  },
  'policy': {
    'cancel': 'Cancel',
    'edit_policy': 'Edit policy',
    'name': 'Name',
    'all_policies': 'All policies',
    'policy_name_required': 'Policy name required',
    'policy_name_present': 'Policy name already exists',
    'update': 'Update',
    'update_policy_success': 'Successfully updated the policy',
    'select_policy': 'Select policy',
    'create_policy': 'Create policy',
    'productivity': 'Productivity',
    'geo_blocking': 'Geo-Blocking',
    'geo_blocking_rules_list': 'Regions',
    'allow': 'Allow',
    'block': 'Block',
    'protection': 'Protection',
    'custom_config': 'Custom configuration',
    'categories_list': 'Categories',
    'go_to_easy_config': 'Go to easy configuration',
    'system_blacklist': 'System Blacklist',
    'choose_policy': 'CHOOSE ONE OF OUR PREDEFINED SECURITY LEVELS',
    'base': 'Basic',
    'medium': 'Medium',
    'high': 'High',
    'custom': 'Custom',
    'add_placeholder': 'youtube.com',
    'search_label': 'Search',
    'search_placeholder': 'Type to search',
    'personal_blacklist': 'Blocked domains',
    'personal_whitelist': 'Allowed domains',
    'search_engine_protection': 'Search Engine',
    'search_engines': 'Google® and Bing® Search Protection',
    'search_engine_tooltip': 'Note: Turn on Google/Bing safe search to limit search results (web pages, images and content), only safe and appropriate results will be shown. Pornography and other undesired content will not be included in the search results',
    'blocked_categories': 'Blocked categories',
    'successfully_changed_config': 'Successfully changed configuration to',
    'empty_domain': 'Please provide a domain',
    'domain_already_added': 'Entered domain is already in use',
    'invalid_domain': 'Please provide a valid domain',
    'successfully_added_to_whitelist': '\'{siteName}\' was successfully added to the whitelist',
    'successfully_removed_from_whitelist': '\'{siteName}\' was successfully removed from the whitelist',
    'successfully_added_to_blacklist': '\'{siteName}\' was successfully added to the blacklist',
    'successfully_removed_from_blacklist': '\'{siteName}\' was successfully removed from the blacklist',
    'successfully_added_to_productivitylist': '\'{siteName}\' was successfully added to my business-specific category',
    'successfully_removed_from_productivitylist': '\'{siteName}\' was successfully removed from my business-specific category',
    'select_device_message': 'Please select a device to continue',
    'enabled_search_engine_protection': 'Search engine protection enabled',
    'disabled_search_engine_protection': 'Search engine protection disabled',
    'selected_device_is_deactivated': 'The selected device is deactivated. Please select another device',
    'legend': 'Legend'
  },
  'categories': {
    'category_business': 'Business-specific category',
    'ads_spam_web_stats': 'Ads, Span & Web Stats',
    'advertising': 'Advertising',
    'domain_for_sale': 'Domain For Sale',
    'e_commerce': 'E-commerce',
    'job_search': 'Job Search',
    'web_stats': 'Web Stats',
    'audio_video': 'Audio & Video',
    'amazon_prime_video': 'Amazon Prime Video',
    'netflix': 'Netflix',
    'others_audio_video': 'Other Audio & Video Providers',
    'spotify': 'Spotify',
    'youtube': 'YouTube',
    'dangerous': 'Dangerous',
    'coronavirus_frauds': 'Corona Virus Frauds',
    'gambling': 'Gambling',
    'p2p_and_filesharing': 'P2P and Filesharing',
    'proxy': 'Proxy',
    'tools_for_hacking': 'Tools for Hacking',
    'blog': 'Blog',
    'books': 'Books',
    'fashion': 'Fashion',
    'free_time': 'Leisure',
    'music': 'Music',
    'pets': 'Pets',
    'photography': 'Photography',
    'radio': 'Radio',
    'religion': 'Religion',
    'shopping': 'Shopping',
    'shows_vips': 'Shows & VIPs',
    'sport': 'Sport',
    'travel': 'Travel',
    'generic_safe_websites': 'Generic safe websites',
    'malware_threats': 'Malware & Threats',
    'backdoor': 'Backdoor',
    'botnet': 'Botnet',
    'dangerous_threats': 'Dangerous Threats',
    'malicious_activity': 'Malicious activity',
    'malicious_dns_activity': 'Malicious DNS activity',
    'malicious_web_sites': 'Malicious web sites',
    'malware_community': 'Malware Community',
    'phishing': 'Phishing',
    'phishing_community': 'Phishing Community',
    'ransomware_and_cryptowall': 'Ransomware and Cryptowall',
    'suspicious_web_activity': 'Suspicious web activity',
    'trojan': 'Trojan',
    'worm': 'Worm',
    'news': 'News',
    'search_engines': 'Search Engines',
    'social_network': 'Social Network',
    'facebook': 'Facebook',
    'instagram': 'Instagram',
    'other_social_networks': 'Other Social Networks',
    'tiktok': 'TikTok',
    'twitter': 'Twitter',
    'whatsapp_vocals_calls_only': 'WhatsApp (voice messages & calls only)',
    'technology': 'Technology',
    'cdn': 'CDN',
    'file_hosting': 'File Hosting',
    'iana_domains_tld_and_arpa': 'IANA Domains, TLD and ARPA',
    'instant_messaging': 'Instant Messaging',
    'online_services': 'Online Services',
    'sip_provider': 'SIP Provider',
    'software_tech': 'Software & Technology',
    'updates': 'Updates',
    'web_mail': 'Web Mail',
    'unwanted_websites': 'Unwanted Websites',
    'adult': 'Adult',
    'chat': 'Chat',
    'colombian_government_blacklist': 'Colombian Government blacklist',
    'dating': 'Dating',
    'desktop_wallpapers': 'Desktop Wallpapers',
    'dns_proxy': 'DNS Proxy',
    'drugs': 'Drugs',
    'games': 'Games',
    'online_auctions': 'Online Auctions',
    'porn': 'Porn',
    'sexuality': 'Sexuality',
    'weapons': 'Weapons',
    'work': 'Work',
    'associations': 'Associations',
    'companies': 'Companies',
    'education_training': 'Education & Training',
    'financial': 'Financial',
    'legal': 'Legal',
    'medical': 'Medical',
    'pa_government': 'PA & Government',
    'others': 'Others',
    'uncategorized': 'Uncategorized', 
    'ads': 'Advertisement',
    'parked_domains': 'Parked Domains',
    'web_advertisements': 'Web Advertisements',
    'hacking': 'Hacking',
    'open_http_proxies': 'Open HTTP Proxies',
    'pay_to_surf': 'Pay to Surf',
    'peer_to_peer': 'Peer to Peer',
    'local_information': 'Local Information',
    'abortion': 'Abortion',
    'military': 'Military',
    'computer_and_internet_info': 'Computer and Internet Info',
    'entertainment_and_arts': 'Entertainment and Arts',
    'fashion_and_beauty': 'Fashion and Beauty',
    'food_and_dining': 'Food and Dining',
    'home_and_garden': 'Home and Garden',
    'hunting_and_fishing': 'Hunting and Fishing',
    'internet_portals': 'Internet Portals',
    'kids': 'Kids',
    'motor_vehicles': 'Motor Vehicles',
    'online_greeting_cards': 'Online Greeting Cards',
    'personal_sites_and_blogs': 'Personal Sites and Blogs',
    'philosophy_and_political_advocacy': 'Philosophy and Politics',
    'real_estate': 'Real Estate',
    'recreation_and_hobbies': 'Recreation and Hobbies',
    'society': 'Society',
    'sports': 'Sports',
    'bot_nets': 'Bot Nets',
    'confirmed_spam_sources': 'Confirmed SPAM Sources',
    'keyloggers_and_monitoring': 'Keyloggers and Monitoring',
    'malware_sites': 'Malware Sites',
    'phishing_and_other_frauds': 'Phishing and Other Frauds',
    'proxy_avoidance_and_anonymizers': 'Hidden Proxies and Anonymizers',
    'spam_urls': 'Spam URLs',
    'spyware_and_adware': 'Spyware and Adware',
    'unconfirmed_spam_sources': 'Unconfirmed SPAM Sources',
    'media': 'Media',
    'news_and_media': 'News and Media',
    'social_networking': 'Social Networking',
    'streaming_media': 'Streaming Media',
    'image_and_video_search': 'Image and Video Search',
    'computer_and_internet_security': 'Computer and Internet Security',
    'content_delivery_networks': 'Content Delivery Networks',
    'dynamically_generated_content': 'Dynamically Generated Content',
    'individual_stock_advice_and_tools': 'Individual Stock Advice and Tools',
    'internet_communications': 'Internet Communications',
    'personal_storage': 'Personal Storage',
    'shareware_and_freeware': 'Shareware and Freeware',
    'translation': 'Translation',
    'web_hosting': 'Web Hosting',
    'web_based_email': 'Web-based Email',
    'abused_drugs': 'Drugs',
    'adult_and_pornography': 'Adult and Pornography',
    'alcohol_and_tobacco': 'Alcohol and Tobacco',
    'auctions': 'Auctions',
    'cheating': 'Cheating',
    'cult_and_occult': 'Cult and Occult',
    'dead_sites': 'Dead Sites',
    'gross': 'Repulsive content',
    'hate_and_racism': 'Hate and Racism',
    'illegal': 'Illegal',
    'marijuana': 'Marijuana',
    'Military': 'Military',
    'nudity': 'Nudity',
    'private_ip_addresses': 'Private IP Addresses',
    'sex_education': 'Sex Education',
    'swimsuits_and_intimate_apparel': 'Swimsuits and Intimate Apparel',
    'violence': 'Violence',
    'business_and_economy': 'Business and Economy',
    'educational_institutions': 'Educational Institutions',
    'financial_services': 'Financial Services',
    'government': 'Government',
    'health_and_medicine': 'Health and Medicine',
    'reference_and_research': 'Reference and Research',
    'training_and_tools': 'Educational Tools',
    'self_harm': 'Self harm',
    'dns_over_https': 'DNS Over HTTPS',
    'low_thc_cannabis_products': 'Low-THC Cannabis Products',
    'generative_ai': 'Generative AI'
  },
  'regions': {
    "afghanistan": "Afghanistan",
    "africa": "Africa",
    "aland_islands": "Aland Islands",
    "albania": "Albania",
    "algeria": "Algeria",
    "american_samoa": "American Samoa",
    "andorra": "Andorra",
    "angola": "Angola",
    "anguilla": "Anguilla",
    "anonymous_proxy": "Anonymous Proxy",
    "antarctica_(the_territory_south_of_60_deg_s)": "Antarctica (the territory South of 60 deg S)",
    "antarctica": "Antarctica",
    "antigua_and_barbuda": "Antigua and Barbuda",
    "argentina": "Argentina",
    "armenia": "Armenia",
    "aruba": "Aruba",
    "asia_pacific_region": "Asia pacific region",
    "asia": "Asia",
    "australia": "Australia",
    "austria": "Austria",
    "azerbaijan": "Azerbaijan",
    "bahamas": "Bahamas",
    "bahrain": "Bahrain",
    "baltics": "Baltics",
    "bangladesh": "Bangladesh",
    "barbados": "Barbados",
    "belarus": "Belarus",
    "belgium": "Belgium",
    "belize": "Belize",
    "benin": "Benin",
    "bermuda": "Bermuda",
    "bhutan": "Bhutan",
    "bolivia": "Bolivia",
    "bonaire_saint_eustatius_and_saba": "Bonaire Saint Eustatius and Saba",
    "bonaire": "Bonaire",
    "bosnia_and_herzegovina": "Bosnia and Herzegovina",
    "botswana": "Botswana",
    "bouvet_island_(bouvetoya)": "Bouvet Island (Bouvetoya)",
    "brazil": "Brazil",
    "british_indian_ocean_territory_(chagos_archipelago)": "British Indian Ocean Territory (Chagos Archipelago)",
    "british_indian_ocean_territory": "British Indian ocean territory",
    "british_virgin_islands": "British Virgin Islands",
    "brunei_darussalam": "Brunei Darussalam",
    "bulgaria": "Bulgaria",
    "burkina_faso": "Burkina Faso",
    "burundi": "Burundi",
    "cambodia": "Cambodia",
    "cameroon": "Cameroon",
    "canada": "Canada",
    "cape_verde": "Cape Verde",
    "cayman_islands": "Cayman Islands",
    "central_african_republic": "Central African Republic",
    "chad": "Chad",
    "chile": "Chile",
    "china": "China",
    "christmas_island": "Christmas Island",
    "cocos_(keeling)_islands": "Cocos (Keeling) Islands",
    "colombia": "Colombia",
    "comoros": "Comoros",
    "congo_the_democratic_republic_of_the": "Congo, The Democratic Republic of the",
    "congo": "Congo",
    "cook_islands": "Cook Islands",
    "costa_rica": "Costa Rica",
    "cote_d_ivoire": "Cote d'Ivoire",
    "croatia": "Croatia",
    "cuba": "Cuba",
    "curacao": "Curacao",
    "cyprus": "Cyprus",
    "czech_republic": "Czech Republic",
    "denmark": "Denmark",
    "disputed_territory": "Disputed Territory",
    "djibouti": "Djibouti",
    "dominica": "Dominica",
    "dominican_republic": "Dominican Republic",
    "eastern_europe": "Eastern Europe",
    "ecuador": "Ecuador",
    "egypt": "Egypt",
    "el_salvador": "El Salvador",
    "equatorial_guinea": "Equatorial Guinea",
    "eritrea": "Eritrea",
    "estonia": "Estonia",
    "ethiopia": "Ethiopia",
    "europe": "Europe",
    "falkland_islands_(malvinas)": "Falkland Islands (Malvinas)",
    "faroe_islands": "Faroae Islands",
    "fiji": "Fiji",
    "finland": "Finland",
    "france": "France",
    "french_guiana": "French Guiana",
    "french_polynesia": "French Polynesia",
    "french_southern_territories": "French Southern Territories",
    "gabon": "Gabon",
    "gambia": "Gambia",
    "georgia": "Georgia",
    "germany": "Germany",
    "ghana": "Ghana",
    "gibraltar": "Gibraltar",
    "greece": "Greece",
    "greenland": "Greenland",
    "grenada": "Grenada",
    "guadeloupe": "Guadeloupe",
    "guam": "Guam",
    "guatemala": "Guatemala",
    "guernsey": "Guernsey",
    "guinea_bissau": "Guinea Bissau",
    "guinea": "Guinea",
    "guyana": "Guyana",
    "haiti": "Haiti",
    "heard_island_and_mcdonald_islands": "Heard Island and McDonald Islands",
    "holy_see_(vatican_city_state)": "Holy See (Vatican City State)",
    "honduras": "Honduras",
    "hong_kong": "Hong Kong",
    "hungary": "Hungary",
    "iceland": "Iceland",
    "india": "India",
    "indonesia": "Indonesia",
    "ip_not_defined": "Ip not defined",
    "iran_islamic_republic_of": "Iran, Islamic Republic of",
    "iran": "Iran",
    "iraq_saudi_arabia_neutral_zone": "Iraq-Saudi Arabia Neutral Zone",
    "iraq": "Iraq",
    "ireland": "Ireland",
    "isle_of_man": "Isle of Man",
    "israel": "Israel",
    "italy": "Italy",
    "jamaica": "Jamaica",
    "japan": "Japan",
    "jersey": "Jersey",
    "jordan": "Jordan",
    "kazakhstan": "Kazakhstan",
    "kenya": "Kenya",
    "kiribati": "Kiribati",
    "north_korea": "North Korea",
    "south_korea": "South Korea",
    "korea": "Korea",
    "kuwait": "Kuwait",
    "kyrgyz_republic": "Kyrgyz Republic",
    "kyrgyzstan": "Kyrgyzstan",
    "lao_people's_democratic_republic": "Lao People\'s Democratic Republic",
    "latin_america_and_caribbean": "Latin America and Caribbean",
    "latvia": "Latvia",
    "lebanon": "Lebanon",
    "lesotho": "Lesotho",
    "liberia": "Liberia",
    "libya": "Libya",
    "libyan_arab_jamahiriya": "Libyan Arab Jamahiriya",
    "liechtenstein": "Liechtenstein",
    "lithuania": "Lithuania",
    "luxembourg": "Luxembourg",
    "macao": "Macao",
    "macau": "Macau",
    "macedonia": "Macedonia",
    "madagascar": "Madagascar",
    "malawi": "Malawi",
    "malaysia": "Malaysia",
    "maldives": "Maldives",
    "mali": "Mali",
    "malta": "Malta",
    "marshall_islands": "Marshall Islands",
    "martinique": "Martinique",
    "mauritania": "Mauritania",
    "mauritius": "Mauritius",
    "mayotte": "Mayotte",
    "mexico": "Mexico",
    "micronesia_federated_states_of": "Micronesia Federated States of",
    "micronesia": "Micronesia",
    "middle_east": "Middle East",
    "moldova_republic_of": "Moldova Republic of",
    "moldova": "Moldova",
    "monaco": "Monaco",
    "mongolia": "Mongolia",
    "montenegro": "Montenegro",
    "montserrat": "Montserrat",
    "morocco": "Morocco",
    "mozambique": "Mozambique",
    "myanmar": "Myanmar",
    "namibia": "Namibia",
    "nauru": "Nauru",
    "nepal": "Nepal",
    "netherlands_antilles": "Netherlands Antilles",
    "netherlands": "Netherlands",
    "new_caledonia": "New Caledonia",
    "new_zealand": "New Zealand",
    "nicaragua": "Nicaragua",
    "niger": "Niger",
    "nigeria": "Nigeria",
    "niue": "Niue",
    "norfolk_island": "Norfolk Island",
    "north_africa": "North Africa",
    "north_america": "North America",
    "northern_mariana_islands": "Northern Mariana Islands",
    "norway": "Norway",
    "oceania": "Oceania",
    "oman": "Oman",
    "pakistan": "Pakistan",
    "palau": "Palau",
    "palestinian_territory": "Palestinian Territory",
    "panama": "Panama",
    "papua_new_guinea": "Papua New Guinea",
    "paraguay": "Paraguay",
    "peru": "Peru",
    "philippines": "Philippines",
    "pitcairn_islands": "Pitcairn Islands",
    "poland": "Poland",
    "portugal": "Portugal",
    "puerto_rico": "Puerto Rico",
    "qatar": "Qatar",
    "reunion": "Reunion",
    "romania": "Romania",
    "russia_and_c_w_of_ind_states": "Russia and C.W. of Ind. States",
    "russian_federation": "Russian Federation",
    "rwanda": "Rwanda",
    "saint_barthelemy": "Saint Barthelemy",
    "saint_helena": "Saint Helena",
    "saint_kitts_and_nevis": "Saint Kitts and Nevis",
    "saint_lucia": "Saint Lucia",
    "saint_martin": "Saint Martin",
    "saint_pierre_and_miquelon": "Saint Pierre and Miquelon",
    "saint_vincent_and_the_grenadines": "Saint Vincent and the Grenadines",
    "samoa": "Samoa",
    "san_marino": "San Marino",
    "sao_tome_and_principe": "Sao Tome and Principe",
    "satellite_connections": "Satellite connections",
    "satellite_provider": "Satellite Provider",
    "saudi_arabia": "Saudi Arabia",
    "senegal": "Senegal",
    "serbia": "Serbia",
    "seychelles": "Seychelles",
    "sierra_leone": "Sierra Leone",
    "singapore": "Singapore",
    "sint_maarten_dutch_part": "Sint Maarten Dutch Part",
    "sint_maarten_(netherlands)": "Sint Maarten (Netherlands)",
    "slovakia_(slovak_republic)": "Slovakia (Slovak Republic)",
    "slovakia": "Slovakia",
    "slovenia": "Slovenia",
    "solomon_islands": "Solomon Islands",
    "somalia": "Somalia",
    "south_africa": "South Africa",
    "south_america": "South America",
    "south_georgia_and_the_south_sandwich_islands": "South Georgia and The South Sandwich Islands",
    "south_sudan": "South Sudan",
    "spain": "Spain",
    "spratly_islands": "Spratly Islands",
    "sri_lanka": "Sri Lanka",
    "sub_saharan_africa": "Sub-Saharan Africa",
    "sudan": "Sudan",
    "suriname": "Suriname",
    "svalbard_and_jan_mayen": "Svalbard and Jan Mayen",
    "svalbard_jan_mayen_islands": "Svalbard & Jan Mayen Islands",
    "swaziland": "Swaziland",
    "sweden": "Sweden",
    "switzerland": "Switzerland",
    "syrian_arab_republic": "Syrian Arab Republic",
    "taiwan": "Taiwan",
    "tajikistan": "Tajikistan",
    "tanzania": "Tanzania",
    "thailand": "Thailand",
    "timor_leste": "Timor-Leste",
    "togo": "Togo",
    "tokelau": "Tokelau",
    "tonga": "Tonga",
    "trinidad_and_tobago": "Trinidad and Tobago",
    "tunisia": "Tunisia",
    "turkey": "Turkey",
    "turkmenistan": "Turkmenistan",
    "turks_and_caicos_islands": "Turks and Caicos Islands",
    "tuvalu": "Tuvalu",
    "uganda": "Uganda",
    "ukraine": "Ukraine",
    "united_arab_emirates": "United Arab Emirates",
    "united_kingdom_of_great_britain_&_northern_ireland": "United Kingdom of Great Britain & Northern Ireland",
    "united_kingdom": "United Kingdom",
    "united_nations_neutral_zone": "United Nations Neutral Zone",
    "united_states_minor_outlying_islands": "United States Minor Outlying Islands",
    "united_states_of_america": "United States of America",
    "united_states_virgin_islands": "United States Virgin Islands",
    "united_states": "United States",
    "uruguay": "Uruguay",
    "uzbekistan": "Uzbekistan",
    "vanuatu": "Vanuatu",
    "venezuela": "Venezuela",
    "vietnam": "Vietnam",
    "virgin_islands_british": "Virgin Islands British",
    "virgin_islands_u_s": "Virgin Islands, U.S.",
    "wallis_and_futuna": "Wallis and Futuna",
    "western_sahara": "Western Sahara",
    "yemen": "Yemen",
    "zambia": "Zambia",
    "zimbabwe": "Zimbabwe",
  },
  'profile': {
    'first_name': 'First name',
    'last_name': 'Last name',
    'email': 'Email',
    'no_of_devices': 'No. of added devices',
    'devices': 'Devices',
    'support': 'Support',
    'details_support': 'Support details',
    'reseller_token': 'Reseller Token',
    'manual': 'Guide',
    'current_password': 'Current password',
    'manual_url': 'https://storage.googleapis.com/bssdownloads/Dm953-I-BeSafeSmartUserManual.pdf'
  },
  'role': {
    'account_managers': 'Admins',
    'reseller_manager': 'Reseller Manager',
    'reseller_managers': 'Resellers Managers',
    'end_users': 'End Users',
    'account_manager': 'Admin',
    'end_user': 'End User',
    'role': 'Role',
    'reseller_user': 'Reseller User',
    'resellers': 'Resellers'
  },
  'users': {
    'create_user': 'Create user',
    'no_users_found': 'No users found',
    'search_placeholder': 'Search by Username or Email',
    'delete': 'Delete user',
    'confirm_delete': 'You cannot undo this action. Do you want to continue?'
  },
  'accounts': {
    'accounts': 'Customers',
    'create_account': 'Create new customer',
    'name_account': 'Client name',
    'account_name_required': 'Customer name required',
    'create_end_user': 'Create new user',
    'total_licenses': 'Total licenses',
    'create_end_user': 'Create user',
    'new_licenses': 'New licenses',
    'new_duration': 'New duration',
    'create_account_success': 'Customer created successfully',
    'create_account_manager': 'Customer new customer manager',
    'available_licenses': 'Available licenses',
    'select_account': 'Select customer',
    'license_type': 'License type',
    'edit_licenses': 'Add licenses',
    'edit': 'Edit Customer',
    'search_placeholder': 'Search by Account name'
  },
  'resellers': {
    'delete_success': 'Reseller successfully deleted',
    'delete_error': 'Error while deleting a reseller'
  },
  'licenses': {
    'licenses': 'Licenses',
    'license': 'License',
    'expiration_license': 'License Expiration',
    'duration': 'License Duration (Months)',
    'type': 'Licencias Type',
  },
  'productivity': {
    'no_category_found': 'No categories found',
    'search_placeholder': 'Search by Category'
  },
  'administration': {
    'create_demo_organization': 'Create demo customer',
    'create_reseller': 'Create reseller',
    'name': 'Name',
    'empty_name': 'Error: Empty name',
    'invalid_repeated_name': 'Error: Name already registered',
    'phone': 'Phone',
    'contact': 'Contact',
    'search_by_name': 'Search by name...',
    'delete_reseller': 'Delete reseller',
    'create_organization': 'Create customer',
    'delete_account': 'Delete customer',
    'successfully': 'Successfully',
    'organization': 'Customer',
    'reseller': 'Reseller',
    'hours': 'Hours',
    'openning_hours': 'M-F 08:00-15:00',
    'besafe_points': 'Besafe points',
    'total_besafe_points': 'Total besafe points',
    'new_besafe_points': 'New besafe points',
    'invalid_besafe_points': 'Error: Invalid besafe points',
  },
  'subscription': {
    'billing_address_not_found': 'Billing address not found',
    'status': 'Status',
    'license_used': 'License used',
    'licenses_available': 'Licenses available',
    'price': 'Price',
    'invoices': 'Invoices',
    'next_billing_date': 'Next billing date',
    'edit_payment_method': 'Edit payment method',
    'cancel_subscription': 'Cancel subscription',
    'cancel_subscription_success': 'Subscription successfully canceled',
    'reactivate_subscription': 'Reactivate subscription',
    'reactivate_subscription_success': 'Subscription successfully reactivated',
    'ending_in': 'ending in',
    'expires': 'expires',
    'payment_method': 'Payment method',
    'expiration_date': 'Expiration date',
    'terms_and_conditions': 'Terms and conditions',
    'accepted_on': 'accepted on',
    'amount': 'Amount',
    'period': 'Period',
    'date': 'Date',
    'confirm_cancel': 'Do you want to cancel the subscription?',
    'update_payment': 'Update payment method',
    'update_payment_success': 'Payment method successfully updated'
  },
  'helpers': {
    'device_name': 'Device name is used to identify each device',
    'device_code': 'Device code is the serial number of your device.',
    'ip_type': '<li><b>IP Static:</b> Use this option if your device has an static IP (ex: 85.24.63.13).</li><li><b>External dynamic IP:</b>Use this option if your device has dynamic IP and you already have an external dyndns service (ex: device_01.yourcompany.com)</li><li><b>Internal dynamic IP:</b>Use this option if your device has a dynamic IP and you do not have any external dyndns service. You can register a hostname with \‘*.cloudteldat.com\’ as internal domain.</li>',
    'switch': '<li>Switch on: traffic allowed</li><li>Switch off: traffic blocked</li>'
  }
}
