<template>
  <b-nav-item-dropdown id="dropdown-2" toggle-class="px-3 py-2" menu-class="language-dropdown">
    <template #button-content>
      <img
        :src="`${baseUrl}img/flags/${getLang()}.png`"
        alt
      > &nbsp; {{ selectedLang }}
    </template>
    <b-dropdown-item
      v-for="(langValue, langKey) in langList"
      :key="langKey"
      class="language-drop-down"
      @click="setLang(langKey)"
    >
      <img
        :src="`${baseUrl}img/flags/${langKey}.png`"
        alt
      > {{ langValue }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
export default {
  name: 'LanguageDropdown',
  data () {
    return {
      langMaps: { 'de': this.$t('language.german'), 'en': this.$t('language.english'), 'es': this.$t('language.spanish') }
    }
  },
  computed: {
    selectedLang () {
      return this.langMaps[this.getLang()]
    },
    langList () {
      const cloneList = Object.assign({}, this.langMaps)
      delete cloneList[this.getLang()]
      return cloneList
    }
  },
  created () {
    let that = this
    this.$http.get('/users/current_locale').then(response => {
      this.$i18n.set(response.data.user_locale)
    })
  },
  methods: {
    setLang (lang) {
      let that = this
      this.$http.patch('/users/update_locale', { locale: lang })
        .then(response => {
          that.$i18n.set(lang)
        }
      )
    },
    getLang () {
      return this.$i18n.locale() || 'de'
    },
  }
}
</script>
<style scoped lang="scss">
  .language-drop-down img {
    vertical-align: unset;
  }
</style>
