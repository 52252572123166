<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
    <!-- Sidenav toggle -->
    <b-navbar-nav v-if="sidenavToggle" class="align-items-lg-center mr-lg-4">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>
    <b-navbar-brand to="/" class="auto-margin">
      <img class="logo-img pr-1" :src="themeImages.mainLogo" />
    </b-navbar-brand>
    <div class="device-container d-lg-none" @click="$bvModal.show('devices-modal')">
      <span class="device-initial text-capitalize">
        <span v-if="$store.getters.authUser.devices.length != 0 && activeDevice !== null">{{ activeDevice.alias_name.charAt(0) }}</span>
        <span v-else>+</span>
      </span>
    </div>
    <b-collapse id="app-layout-navbar" is-nav>
      <b-navbar-nav class="align-items-lg-center ml-auto">
        <AccountSelector v-if="canSeeOtherAccounts" />
        <language />
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mx-2">|</div>
        <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user" menu-class="py-0">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <img :src="avatar" alt class="d-block ui-w-30 rounded-circle" />
            </span>
          </template>
          <b-dropdown-text class="p-0 p-padding-0">
            <b-card
              no-body
              style="width: 16rem;"
              class="text-center"
            >
              <b-card-body class="p-0">
                <b-card-title class="my-2">{{ name }}</b-card-title>
                <b-card-sub-title v-if="isAccountManager" class="" style="margin-top: 0px; margin-bottom: 2px;">{{ $t('role.account_manager') }} -{{ this.account_name }}</b-card-sub-title>
                <b-card-sub-title v-if="isEndUser" class="" style="margin-top: 0px; margin-bottom: 2px;">{{ $t('role.end_user') }} -{{ this.account_name }}</b-card-sub-title>
                <b-card-sub-title v-if="isReseller && this.reseller" class="" style="margin-top: 0px; margin-bottom: 2px;">{{ $t('administration.reseller') }} - {{this.reseller.name}}</b-card-sub-title>
                <!-- <b-card-sub-title class="my-3">{{ authUser.devices.length }} {{ $t('profile.devices') }}</b-card-sub-title> -->
              </b-card-body>
              <b-btn 
                variant="link" 
                class="text-dark" 
                @click="gotoProfile"
              >
                <i class="ion ion-ios-person text-danger"></i> &nbsp; {{ $t('general.profile') }}
              </b-btn>
              <b-btn 
                v-if="$store.getters.authUser.role === 'account_manager' && customer_id !== -1 && $store.getters.authUser.has_subscription" 
                variant="link" 
                class="text-dark" 
                @click="gotoSubscription">
                <i class="far fa-list-alt text-warning" /> &nbsp; {{ $t('general.subscription') }}
              </b-btn>
              <b-btn 
                v-if="$store.getters.authUser.role === 'account_manager' && customer_id !== -1 && $store.getters.authUser.has_subscription" 
                variant="link" 
                class="text-dark" 
                @click="gotoSupport">
                <i class="far fa-envelope text-info" /> &nbsp; {{ $t('profile.support') }}
              </b-btn>
              <b-btn 
                variant="link" 
                class="text-dark" 
                target="_blank"
                :href="$t('profile.manual_url')">
                <i class="fa fa-book text-success" /> &nbsp; {{ $t('profile.manual') }}
              </b-btn>
              <b-card-footer class="py-1">
                <b-btn variant="link" class="text-dark font-weight-semibold" @click="logOutUser">
                  <i class="ion ion-ios-log-out text-danger"></i> &nbsp; {{ $t('general.logout') }}
                </b-btn>
              </b-card-footer>
            </b-card>
          </b-dropdown-text>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <DeviceGroupList v-if="!isReseller" />
  </b-navbar>
</template>

<script>
import router from '@/router'
import Language from './Language.vue'
import AccountSelector from './AccountSelector.vue'
import DeviceGroupList from './DeviceGroupList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AppLayoutNavbar',
  components: {
    DeviceGroupList,
    Language,
    AccountSelector
  },
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      isDropdown2Visible: false,
      account_name: "",
      customer_id: -1,
      reseller: null
    }
  },
  computed: {
    ...mapGetters(['themeImages', 'activeDevice', 'authUser', 'canSeeOtherAccounts', 'isReseller', 'isAccountManager', 'isEndUser']),
    name() {
      if (this.$store.getters.isAuthenticated) {
        return `${this.authUser.first_name} ${this.authUser.last_name}`
      } else {
        return ''
      }
    },
    avatar() {
      if (this.$store.getters.isAuthenticated) {
        return this.$store.getters.authUser.avatar_url
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.getReseller()
    this.getAccount()
    this.watchForAuth()
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if(this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods: {
    getReseller(){
      if (this.authUser.reseller_id != null) {
        this.$http.get('/resellers/' + this.authUser.reseller_id, { }).then(response => {
          this.reseller = response.data
        })
      } 
    },
    gotoSubscription() {
      this.$router.push({ name: 'subscription'})
    },
    gotoProfile() {
      this.$router.push({ name: 'profile'})
    },
    gotoSupport() {
      this.$router.push({ name: 'support'})
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    },
    logOutUser() {
      this.$store.dispatch('logOut')
    },
    getAccount(){
      if (this.authUser.account_id != null) {
        this.$http.get('/accounts/' + this.authUser.account_id, { }).then(response => {
          if(response.data.name != null){
            this.account_name = response.data.name
            this.customer_id = typeof response.data.customer_id !== 'undefined' ? response.data.customer_id : -1
          } else {
            this.account_name = "Teldat Superadmin"
          }
          
        })
      } else {
        this.account_name = this.authUser.name
      }
    },
    watchForAuth() {
      setTimeout(() => {
        let obj = JSON.parse(localStorage.getItem('vuex'))
        if (obj !== null && obj.user.authUser === null && this.$router.currentRoute.name !== 'login') {
          this.$router.go()
        } else {
          this.watchForAuth()
        }
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.home_brand {
  max-width: 20px;
}
.auto-margin {
  margin: auto;
}
.device-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

</style>

<style lang="scss" scoped>
  .logo-img{
    height: 25px;
    width: auto;
  }
  .device-initial {
    display: initial;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 30px;
    background: var(--primary-color);
    color: var(--sidenav-text-color);
    border-radius: 50%;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    padding: 0.4em;
    margin-right: 0;
  }
  .group-initial {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.7rem;
    height: 1.7rem;
    background: #4E5155;
    color: var(--sidenav-text-color);
    border-radius: 50%;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    padding: 0.4em;
    margin-right: 0;
  }
  .layout-navbar{
    border-bottom: 1px solid #e0e0e0;
  }
</style>
