<template>
  <div>
    <div
      v-for="j in Array(totalBlocks).fill().map((v, i) => i)"
      :key="j"
    >
      <div v-if="!hideHeader" class="header" />
      <div
        v-for="i in new Array(rows)"
        :key="i"
        class="row loader-row"
      >
        <div class="col-md-12 col-container">
          <div class="text-line" :style="{ height: `${colHeight}` }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LineLoader',
  props: {
    blocks: {
      type: Number
    },
    rowsPerBlock: {
      type: Number
    },
    columnHeight: {
      type: String
    },
    hideHeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalBlocks() {
      return this.blocks || 1
    },
    rows() {
      return this.rowsPerBlock || 10
    },
    colHeight() {
      return this.columnHeight || "23px"
    }
  }
}
</script>

<style scoped lang='scss'>
.loader-row {
  margin: 0;
  .col-container {
    padding: 0;
    .text-line {
      height: 1rem;
      width: 95%;
      margin: 0 auto;
      margin-bottom: 1rem;
    }
  }
  .header {
    height: 1.5rem;
    margin: 2rem 0;
  }
}
</style>
