<template>
  <b-modal id="devices-modal" centered hide-header hide-footer size="sm">
    <div class="devices-modal-header">
      <strong>{{ $t('sidebar.devices') }}</strong>
      <i class="fa fa-times float-right close-button" @click="$bvModal.hide('devices-modal')" />
      <div class="mt-2 d-flex align-items-center">
        <span class="mr-2 d-none d-md-block search-label"> {{ $t('policy.search_label') }}: </span>
        <span class="mr-1 mobile-icon-btn fas fa-search d-md-none text-primary"></span>
        <b-input v-model="searchInput" class="text-input" :placeholder="$t('devices.search_placeholder')" @input="fetchDevices" />
      </div>
    </div>
    <div class="device-list-wrapper" :class="{ 'device-list-align': deviceList.length === 0 }">
      <LineLoader v-if="isLoading" :hide-header="true" :rows-per-block="5" column-height="60px" />
      <div v-else-if="deviceList.length === 0">
        <span class="text-muted"> {{ $t('devices.no_devices_found') }} </span>
      </div>
      <div v-else>
        <div v-for="device in deviceList" :key="`device_${device.id}`" class="seperate-device" @click="setActiveDevice(device)">
          <div class="device-list-container d-flex align-items-center">
            <div class="device-container" @click="$bvModal.show('devices-modal')">
              <span v-if="device.id !== 0" class="device-initial text-capitalize" style="padding-top:3px">{{ device.alias_name.charAt(0) }}</span>
              <i v-else class="ion ion-ios-apps device-initial" />
            </div>
            <span class="device-name" :class="device.id === 0 ? 'font-weight-semibold' : ''">
              {{ $t(device.alias_name, device.alias_name) }}
            </span>
          </div>
          <i v-if="activeDevice !== null && activeDevice.id === device.id" class="fa fa-check active-device" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import LineLoader from '../components/loaders/LineLoader.vue'
import eventBus from '../event-bus';

export default {
  name: 'DeviceGroupList',
  components: {
    LineLoader,
  },
  data () {
    return {
      searchInput: "",
      fetchedDevices: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters(['activeDevice', 'authUser', 'activeAccount']),
    deviceList () {
      let that = this
      let deviceList = []
      deviceList = this.fetchedDevices

      deviceList.some((device, idx) => {
        if(that.activeDevice !== null && device.id == that.activeDevice.id) {
          deviceList.splice(idx, 1)
          deviceList.unshift(device)
        }
      })
      deviceList.some((device, idx) => {
        if(device.id === 0) {
          deviceList.splice(idx, 1)
          deviceList.unshift(device)
        }
      })
      return deviceList
    },
  },
  mounted() {
    this.fetchDevices()
  },
  watch: {
    activeAccount() {
      this.fetchDevices()
    },
  },
  created () {
    eventBus.$on('deviceAddedOrRemoved', this.fetchDevices)
  },
  methods: {
    fetchDevices() {
      this.isLoading = true
      this.$http.get('/devices/', { params: { search: this.searchInput, page: 1, limit: 100, account_id: this.activeAccount.id}}).then(response => {
        response.data.unshift({id: 0, alias_name: 'general.all_devices'})
        this.fetchedDevices = response.data
        this.isLoading = false
        if (!this.fetchedDevices.map(device => device.id).includes(this.activeDevice.id)) {
          this.$store.dispatch('updateActiveDevice', this.fetchedDevices[0])
        }
      })
    },
    openModal () {
      this.$bvModal.show('devices-modal')
    },
    setActiveDevice (device) {
      this.$store.dispatch('updateActiveDevice', device)
      this.$bvModal.hide('devices-modal')
    },
  }
}
</script>

<style lang="scss" >
.modal-body {
  padding: 0;
  .devices-modal-header {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    border-bottom: 2px solid var(--border-color);
    .close-button {
      font-size: 0.75rem;
      cursor: pointer;
    }
    .search-label {
      font-weight: 500;
      font-size: 0.9rem;
    }
    .text-input {
      padding: 0.3rem;
      max-width: 17.5rem;
      margin: auto;
    }
    .text-input::placeholder {
      font-size: 0.7rem;
    }
  }
  .device-list-align {
    justify-content: center;
    align-items: center;
  }
  .device-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 22rem;
    overflow: auto;
    border-bottom: 2px solid var(--border-color);

    .seperate-device {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 1rem;
      padding: 1.2rem;
      &:hover{
        background: var(--border-color);
      }
      .device-list-container {
        white-space: nowrap;
        overflow: hidden;
        .device-container {
          display: flex;
          align-items: center;
          &:hover{
            color: #4E5155;
          }
          .device-initial {
            flex-shrink: 0;
            width: 2rem !important;
            height: 2rem !important;
            margin-right: 0.5rem !important;
          }
          .group-initial {
            background: #4E5155;
            flex-shrink: 0;
            width: 2rem !important;
            height: 2rem !important;
            margin-right: 0.5rem !important;
          }
        }
        .device-name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .active-device {
        color: var(--primary-color);
        background: var(--border-color);
        border-radius: 50%;
        width: 22px;
        height: 22px;
        text-align: center;
        margin-left: 5px;
        font-size: 0.8rem;
        line-height: 1.5rem;
        padding-right: 1px;
        flex-shrink: 0;
      }
    }
  }
  .footer {
    text-align: center;
    padding: 1.5rem;
  }
}
</style>
