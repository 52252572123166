import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'

//const host = location.hostname
//const port = 3000
//`http://${host}:${port}/api`
Vue.prototype.$http = axios
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3000/api' //`http://${host}:${port}/api`//
axios.defaults.withCredentials = true
axios.defaults.headers.Accept = 'application/json'
axios.defaults.headers.X_REQUESTED_WITH = 'XMLHttpRequest'

const renderLogin = async () => {
  Vue.toasted.show('Invalid credentials or Session Expired', { type: 'error', duration: 2000 })
  await store.dispatch('logOut')
  await router.replace({ name: 'login' })
}

const restrictedPage = () => {
  Vue.toasted.show('You are not authorized to do that.', {
    type: 'error', duration: 2000
  })
  router.push('/')
}

const pageNotFound = () => {
  router.push('/Not-Found-404')
}

const somethingWrong = () => {
  Vue.toasted.show('Something went wrong. Please retry.', {
    type: 'error', duration: 2000
  })
}

axios.interceptors.request.use((config) => {
  return config
})

axios.interceptors.response.use(
  (response) => {
    const feVersion = response.headers['fe-version'] || 'default'
    if (feVersion !== localStorage.getItem('fe-version') && response.config.method === 'get') {
      localStorage.setItem('fe-version', feVersion)
      window.location.reload() // When new version is found simply reload on get request
    }
    return Promise.resolve(response)
  },
  (error) => {
    if (error.config.hasOwnProperty('handleErrors') && !error.config.handleErrors) {
      return Promise.reject(error)
    }
    if (error.message == 'Network Error') {
      Vue.toasted.show('Check your API server, it may not be running', {
        type: 'error', duration: 2000
      })
      return
    }
    if (error.response.status === 400 && error.response.data.error) {
      Vue.toasted.show(error.response.data.error, {
        type: 'error', duration: 2000
      })
    } else if (error.response.status === 401) {
      renderLogin()
    } else if (error.response.status === 403) {
      restrictedPage()
    } else if (error.response.status === 404) {
      pageNotFound()
    } else if (error.response.status === 422) {
      // These are validation errors from backend, just ignore it.
    } else {
      somethingWrong()
    }
  }
)
