<template>
  <div>
    <b-button
      v-if="canManageOtherAccountsAndUsers"
      variant="primary"
      class="d-none d-sm-inline tiny-sm-text"
      @click="$bvModal.show('account-selector-modal')"
    >
      <span
        v-if="activeAccount && activeAccount.id !== 0"
        class="account-initial text-capitalize"
      >{{ activeAccount.name.charAt(0) }}</span
      >
      <i v-else class="ion ion-ios-apps account-initial" />
      <span class="ml-2" style="color:white;">
        {{ $t(activeAccount.name, activeAccount.name) }}
      </span>
    </b-button>
    <b-modal id="account-selector-modal" class="modal-body" centered hide-header hide-footer size="sm">
      <div class="account-selector-modal-header">
        <strong>{{ $t('sidebar.accounts') }}</strong>
        <i class="fa fa-times float-right close-button" @click="$bvModal.hide('account-selector-modal')" />
        <div class="mt-2 d-flex align-items-center">
          <span class="mr-2 d-none d-md-block search-label"> {{ $t('policy.search_label') }}: </span>
          <span class="mr-1 mobile-icon-btn fas fa-search d-md-none text-primary"></span>
          <b-input v-model="searchInput" class="text-input" :placeholder="$t('accounts.search_placeholder')" @input="fetchAccounts" />
        </div>
      </div>
      <div class="account-list-wrapper" :class="{ 'account-list-align': accountList.length === 0 }">
        <LineLoader v-if="isLoading" :hide-header="true" :rows-per-block="5" column-height="60px" />
        <div v-else-if="accountList.length === 0">
          <span class="text-muted"> {{ $t('accounts.no_accounts_found') }} </span>
        </div>
        <div v-else>
          <div v-for="account in accountList" :key="`account_${account.id}`" class="seperate-account" @click="setActiveAccount(account)">
            <div class="account-list-container d-flex align-items-center">
              <div class="account-container" @click="$bvModal.show('account-selector-modal')">
                <span v-if="account.id !== 0" class="account-initial text-capitalize" style="padding-top:3px">{{ account.name.charAt(0) }}</span>
                <i v-else class="ion ion-ios-apps account-initial" />
              </div>
              <span class="account-name" :class="account.id === 0 ? 'font-weight-semibold' : ''">
                {{ $t(account.name, account.name) }}
              </span>
            </div>
            <i v-if="activeAccount !== null && activeAccount.id === account.id" class="fa fa-check active-account" />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LineLoader from '../components/loaders/LineLoader.vue'
export default {
  name: 'AccountSelector',
  components: {
    LineLoader
  },
  data () {
    return {
      searchInput: "",
      fetchedAccounts: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters(['activeAccount', 'canManageOtherAccountsAndUsers']),
    accountList () {
      let that = this
      let accountList = this.fetchedAccounts

      accountList.some((account, idx) => {
        if(that.activeAccount !== null && account.id == that.activeAccount.id) {
          accountList.splice(idx, 1)
          accountList.unshift(account)
        }
      })
      accountList.some((account, idx) => {
        if(account.id === 0) {
          accountList.splice(idx, 1)
          accountList.unshift(account)
        }
      })
      return accountList
    },
  },
  created () {
    this.fetchAccounts()
  },
  methods: {
    fetchAccounts() {
      this.isLoading = true
      this.$http.get('/accounts', { params: { search: this.searchInput, page: 1, limit: 10}}).then(response => {
        response.data.unshift({id: 0, name: 'general.all_accounts'})
        this.fetchedAccounts = response.data
        this.isLoading = false
      })
    },
    setActiveAccount (account) {
      this.$store.dispatch('updateActiveAccount', account)
      this.$bvModal.hide('account-selector-modal')
    },
  }
}
</script>
<style scoped lang="scss">
.modal-body {
  padding: 0;
  .account-selector-modal-header {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    border-bottom: 2px solid var(--border-color);
    .close-button {
      font-size: 0.75rem;
      cursor: pointer;
    }
    .search-label {
      font-weight: 500;
      font-size: 0.9rem;
    }
    .text-input {
      padding: 0.3rem;
      max-width: 17.5rem;
      margin: auto;
    }
    .text-input::placeholder {
      font-size: 0.7rem;
    }
  }
  .account-list-align {
    justify-content: center;
    align-items: center;
  }
  .account-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 22rem;
    overflow: auto;
    border-bottom: 2px solid var(--border-color);

    .seperate-account {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 1rem;
      padding: 1.2rem;
      &:hover{
        background: var(--border-color);
      }
      .account-list-container {
        white-space: nowrap;
        overflow: hidden;
        .account-container {
          display: flex;
          align-items: center;
          &:hover{
            color: #4E5155;
          }
          .account-initial {
            flex-shrink: 0;
            width: 2rem !important;
            height: 2rem !important;
            margin-right: 0.5rem !important;
          }
          .group-initial {
            background: #4E5155;
            flex-shrink: 0;
            width: 2rem !important;
            height: 2rem !important;
            margin-right: 0.5rem !important;
          }
        }
        .account-name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .active-account {
        color: var(--primary-color);
        background: var(--border-color);
        border-radius: 50%;
        width: 22px;
        height: 22px;
        text-align: center;
        margin-left: 5px;
        font-size: 0.8rem;
        line-height: 1.5rem;
        padding-right: 1px;
        flex-shrink: 0;
      }
    }
  }
  .footer {
    text-align: center;
    padding: 1.5rem;
  }
}
</style>
